import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Button, IconButton, JustSelect, OptionType } from '@just-ai/just-ui';

import localization, { t } from 'localization';

import { groupedSelectorLocalization } from './localization/groupedSelector.loc';

import styles from './styles.module.scss';

export type OptionsGroup = {
  label: string;
  value: string;
  list: OptionType[];
};

type OptionsInnerGroup = OptionsGroup & {
  options?: string[];
};

localization.addTranslations(groupedSelectorLocalization);

function transformToGroupOptions(groups: OptionsGroup[], selectedOptions: string[]) {
  return groups.map(group => {
    const options = selectedOptions
      .map(option => {
        return group.list.find(el => el.value === option)?.value;
      })
      .filter(Boolean) as string[];
    return { ...group, options } as OptionsInnerGroup;
  });
}

function getOptionValuesFromGroups(groups: OptionsInnerGroup[]): string[] {
  return groups.flatMap(group => group.options || []);
}

type GroupedSelectorType = {
  groups: OptionsGroup[];
  selected: string[];
  onChange(options: string[]): void;
  preselectedGroup?: string;
  fixedGroupWidth?: boolean;
};
const GroupedSelector = ({ selected, groups, onChange, preselectedGroup, fixedGroupWidth }: GroupedSelectorType) => {
  const [groupOptions, setGroupOptions] = useState<OptionsInnerGroup[]>([]);

  useEffect(() => {
    if (groupOptions.length) return;

    setGroupOptions(() => {
      const groupsInner = transformToGroupOptions(groups, selected).filter(group => group.options?.length);
      if (groupsInner.length) return groupsInner;
      return [{ value: '', label: '', list: [] }];
    });
  }, [groupOptions.length, groups, selected]);

  const getAvailableGroupsFor = useCallback(
    (selectedGroup?: OptionsGroup) => {
      return groups.filter(
        group =>
          (selectedGroup && selectedGroup.value === group.value) || !groupOptions.some(el => el.value === group.value)
      );
    },
    [groupOptions, groups]
  );

  const addGroup = useCallback(() => {
    setGroupOptions(prev => [...prev, { label: '', value: '', list: [], options: [] }]);
  }, []);

  const changeGroup = useCallback(
    (value: (string | number)[] | null, index: number) => {
      const groupValue = value?.[0] as string;
      if (!groupValue) return;

      const fullGroup = groups.find(el => el.value === groupValue);
      if (!fullGroup) return;

      setGroupOptions(prev => {
        prev.splice(index, 1, fullGroup);
        return [...prev];
      });
    },
    [groups]
  );

  useLayoutEffect(() => {
    if (preselectedGroup && !groupOptions[0]?.label) {
      changeGroup([preselectedGroup], 0);
    }
  }, [changeGroup, groupOptions, preselectedGroup]);

  const changeGroupValues = useCallback(
    (value: (string | number)[] | null, index: number) => {
      const fullGroup = groupOptions[index];
      if (!fullGroup) return;

      const newGroup = { ...fullGroup, options: value as string[] };
      groupOptions.splice(index, 1, newGroup);
      setGroupOptions([...groupOptions]);
      onChange(getOptionValuesFromGroups([...groupOptions]));
    },
    [groupOptions, onChange]
  );

  const deleteGroup = useCallback(
    (index: number) =>
      setGroupOptions(prev => {
        prev.splice(index, 1);
        const newGroups = [...prev];
        onChange(getOptionValuesFromGroups(newGroups));
        return newGroups;
      }),
    [onChange]
  );

  return (
    <div className={styles.GroupedSelector}>
      {groupOptions.map((group, index) => (
        <div key={`${group.label}-${group.value}-${index}`} className='flex-row gap-2x ai-start flex-nowrap'>
          <JustSelect
            options={getAvailableGroupsFor(group)}
            value={group.value}
            onChange={val => changeGroup(val, index)}
            inputPlaceholder={t('GroupedSelector:Field:Roles:Groups:Placeholder')}
            fullWidth={!fixedGroupWidth}
            size='md'
            position='fixed'
            data-test-id='GroupSelector.GroupSelect'
          />
          <JustSelect
            className={styles.multilineSelect}
            options={group.list}
            value={group.options}
            onChange={val => changeGroupValues(val, index)}
            inputPlaceholder={t('GroupedSelector:Field:Roles:Placeholder')}
            multiple
            fullWidth
            position='fixed'
            data-test-id='GroupSelector.RoleSelect'
          />
          <IconButton name='farTrashAlt' color='secondary' flat onClick={() => deleteGroup(index)} />
        </div>
      ))}

      {getAvailableGroupsFor().length > 0 && groupOptions.length > 0 && groupOptions[groupOptions.length - 1].label && (
        <Button className={styles.actionButton} color='primary' flat onClick={addGroup}>
          {t('GroupedSelector:Field:Roles:Add')}
        </Button>
      )}
    </div>
  );
};

export default GroupedSelector;
