import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { default as TagsInputLib } from 'react-tagsinput';
import cn from 'classnames';
import styles from './TagsInput.module.scss';
const TagsInput = (props) => {
    const inputProps = useMemo(() => { var _a; return (Object.assign(Object.assign({}, props.inputProps), { className: cn(styles.TagInput__input, (_a = props.inputProps) === null || _a === void 0 ? void 0 : _a['className']) })); }, [props.inputProps]);
    const tagProps = useMemo(() => { var _a; return (Object.assign(Object.assign({}, props.tagProps), { className: cn(styles.TagInput__tag, (_a = props.tagProps) === null || _a === void 0 ? void 0 : _a['className']) })); }, [props.tagProps]);
    return (_jsx(TagsInputLib, Object.assign({}, props, { className: cn(styles.TagInput, props.className), inputProps: inputProps, tagProps: tagProps })));
};
export default React.memo(TagsInput);
