export const addUsersLocalization = {
  'AddUserPage:Title': {
    ru: 'Добавить пользователей в аккаунт {accountName}',
    eng: 'Add users to {accountName} account',
  },
  'AddUserPage:Back': {
    ru: 'Назад',
    eng: 'Back',
  },
  'AddUserPage:AccountId': {
    ru: 'ID аккаунта: {accountId}',
    eng: 'Account ID: {accountId}',
  },
  'AddUserPage:Info': {
    ru: 'Пользователи не получат приглашения и будет сразу добавлены в аккаунт.',
    eng: 'The users will not receive an invitation and will be immediately added to the account.',
  },
  'AddUserPage:Info:Remark': {
    ru: 'В поиске не отображаются пользователи, которые уже добавлены в аккаунт или которым высланы приглашения.',
    eng: 'The search does not show users who are already added to the account or who received invitations.',
  },
  'AddUsersPage:Table:Header:Id': {
    ru: 'ID',
    eng: 'ID',
  },
  'AddUsersPage:Table:Header:Login': {
    ru: 'Email / Логин',
    eng: 'Email / Login',
  },
  'AddUsersPage:Table:Header:Name': {
    ru: 'Полное имя / Телефон',
    eng: 'Full name / Phone number',
  },
  'AddUsersPage:Table:Header:Owner': {
    ru: 'Владелец аккаунта',
    eng: 'Account owner',
  },
  'AddUsersPage:Alert:AddedOne': {
    ru: 'Пользователь {email} добавлен в аккаунт {account}',
    eng: 'User {email} added to {account} account',
  },
  'AddUsersPage:Alert:AddedMultiple': {
    ru: 'Пользователи ({size}) добавлены в аккаунт {account}',
    eng: '{size} users added to {account} account',
  },
  'AddUsersPage:Alert:accountsadmin.account.too.many.users.added': {
    ru: 'Не удалось добавить новых пользователей, так как был превышен лимит пользователей в аккаунте.',
    eng: 'Failed to add new users because the account user limit was exceeded.',
  },
  'AddUsersPage:Alert:accountsadmin.account.user_already_member_of_account': {
    ru: 'Один или несколько пользователей уже являются членами аккаунта',
    eng: 'One or more users are already account members',
  },
  'AddUsersPage:Submit': {
    ru: 'Добавить',
    eng: 'Add',
  },
  'AddUsersPage:Selected': {
    ru: 'Выбраны пользователи: ',
    eng: 'Selected users: ',
  },
  'AddUserPage:GroupSelector:Label': {
    eng: 'Groups and roles',
    ru: 'Группы и роли',
  },
  'AddUsersPage:Error:Limit': {
    eng: 'The number of selected users exceeds the limit. Select fewer users',
    ru: 'Количество выбранных пользователей превышает лимит. Выберите меньше пользователей',
  },
  'AddUserPage:Search:Placeholder': {
    eng: 'Enter user email, ID or name',
    ru: 'Введите email, ID или имя пользователя',
  },
  'AddUserPage:Table:Title': {
    eng: 'Users',
    ru: 'Пользователи',
  },
  'AddUsersPage:Alert:Title': {
    ru: 'Добавление пользователей',
    eng: 'Adding users',
  },
};
