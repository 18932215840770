import React, { PureComponent } from 'react';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

interface RecaptchaInterface {
  className: string;
  onloadCallbackName: string;
  elementID: string;
  onloadCallback?: () => void;
  verifyCallback?: (response: any) => void;
  expiredCallback?: () => void;
  render: 'onload' | 'explicit';
  sitekey?: string;
  theme: 'light' | 'dark';
  type: string;
  verifyCallbackName: string;
  expiredCallbackName: string;
  size: 'invisible' | 'compact' | 'normal';
  tabindex: string;
  hl?: string;
  badge: 'bottomright' | 'bottomleft' | 'inline';
}

interface RecaptchaStateInterface {
  ready: boolean;
  widget: number | null;
}

let grecaptcha = window.grecaptcha;

export const isCaptchaReady = () => {
  grecaptcha = window.grecaptcha;
  return typeof window !== 'undefined' && typeof grecaptcha !== 'undefined' && typeof grecaptcha.render === 'function';
};

export default class Recaptcha extends PureComponent<RecaptchaInterface, RecaptchaStateInterface> {
  private readyCheck: NodeJS.Timer | null = null;

  static defaultProps: RecaptchaInterface = {
    elementID: 'g-recaptcha',
    className: 'g-recaptcha',
    onloadCallback: undefined,
    onloadCallbackName: 'onloadCallback',
    verifyCallback: undefined,
    verifyCallbackName: 'verifyCallback',
    expiredCallback: undefined,
    expiredCallbackName: 'expiredCallback',
    render: 'onload',
    theme: 'light',
    type: 'image',
    size: 'normal',
    tabindex: '0',
    hl: undefined,
    badge: 'bottomright',
  };
  state = {
    ready: isCaptchaReady(),
    widget: null,
  };

  constructor(props: RecaptchaInterface) {
    super(props);
    this._renderGrecaptcha = this._renderGrecaptcha.bind(this);
    this.reset = this.reset.bind(this);
    this.state = {
      ready: isCaptchaReady(),
      widget: null,
    };

    if (!this.state.ready && typeof window !== 'undefined') {
      this.readyCheck = setInterval(this._updateReadyState.bind(this), 1000);
    }
  }

  componentDidMount() {
    if (this.state.ready) {
      this._renderGrecaptcha();
    }
  }

  componentDidUpdate(prevProps: RecaptchaInterface, prevState: RecaptchaStateInterface) {
    const { render, onloadCallback } = this.props;

    if (render === 'explicit' && onloadCallback && this.state.ready && !prevState.ready) {
      this._renderGrecaptcha();
    }
  }

  componentWillUnmount() {
    this.readyCheck && clearInterval(this.readyCheck);
  }

  reset() {
    const { ready, widget } = this.state;
    if (ready && widget !== null) {
      grecaptcha.reset(widget);
    }
  }

  execute() {
    const { ready, widget } = this.state;
    if (ready && widget !== null) {
      grecaptcha.execute(widget);
    }
  }

  _updateReadyState() {
    if (isCaptchaReady()) {
      this.setState({
        ready: true,
      });
      this._renderGrecaptcha();

      this.readyCheck && clearInterval(this.readyCheck);
    }
  }

  _renderGrecaptcha() {
    if (!this.props.elementID) return;
    this.state.widget = grecaptcha.render(this.props.elementID, {
      sitekey: this.props.sitekey,
      callback: this.props.verifyCallback ? this.props.verifyCallback : undefined,
      theme: this.props.theme,
      type: this.props.type,
      size: this.props.size,
      tabindex: this.props.tabindex,
      hl: this.props.hl,
      badge: this.props.badge,
      'expired-callback': this.props.expiredCallback ? this.props.expiredCallback : undefined,
    });

    if (this.props.onloadCallback) {
      this.props.onloadCallback();
    }
  }

  render() {
    if (this.props.render === 'explicit' && this.props.onloadCallback) {
      return (
        <div
          id={this.props.elementID}
          data-onloadcallbackname={this.props.onloadCallbackName}
          data-verifycallbackname={this.props.verifyCallbackName}
        />
      );
    }

    return (
      <div
        id={this.props.elementID}
        className={this.props.className}
        data-sitekey={this.props.sitekey}
        data-theme={this.props.theme}
        data-type={this.props.type}
        data-size={this.props.size}
        data-badge={this.props.badge}
        data-tabindex={this.props.tabindex}
      />
    );
  }
}
