import React from 'react';
import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';

import { t } from 'localization';
import { appStores } from 'store';

import isAccess, { isInternal } from 'helpers/isAccessFunction';
import BadgeGroups from 'views/AccountDetail/components/BadgeGroups';

import styles from './styles.module.scss';

interface AccountsUserRowProps {
  user: UserSearchRecord;
  onEdit: (user: UserSearchRecord) => void;
  onDelete: (user: UserSearchRecord) => void;
}
const AccountsUserRow = ({ user, onEdit, onDelete }: AccountsUserRowProps) => {
  const { currentUserId, groupsAndRoles } = {
    ...appStores.CurrentUser(state => ({ currentUserId: state.user?.userId })),
    ...appStores.Accounts(state => ({ groupsAndRoles: state.groupsAndRoles })),
  };

  const isHasAccessToUserEdit = (isAccess('ROLES_WRITE') || isInternal()) && !user.owner;
  return (
    <tr className={styles.AccountsUserRow} data-test-id={`AccountsUserRow.userListTable.userListItem.${user.login}`}>
      <td className={styles.AccountsUserRow__email} data-test-id='AccountsUserRow.userListTable.ListItem.email'>
        <div>{user.email}</div>
        <div className={styles.AccountsUserRow__id}>
          <p className='mb-0'>{user.id}</p>
          {user.owner && <p className='mb-0'>{`| ${t('AccountsUserRow:Owner')}`}</p>}
        </div>
      </td>
      <td className={styles.AccountsUserRow__fullName}>
        <p>{user.fullName}</p>
      </td>
      <td className={styles.AccountsUserRow__accounts}>
        <BadgeGroups selected={user.roles} groups={groupsAndRoles} />
      </td>
      <td className={styles.AccountsUserRow__button}>
        {isHasAccessToUserEdit && (
          <DropdownButton data-test-id={`AccountsPage.AccountCard.${user.login}.dropdown`} direction='down'>
            <DropdownToggle iconButton compact size='md'>
              <Icon name='farEllipsisH' color='secondary' size='md' />
            </DropdownToggle>
            <DropdownMenu right>
              {isHasAccessToUserEdit && (
                <DropdownItem onClick={() => onEdit(user)} data-test-id={`AccountsUserRow.${user.login}.dropdown.Edit`}>
                  {t('AccountsUserRow:Edit')}
                </DropdownItem>
              )}
              {isHasAccessToUserEdit && user.id !== currentUserId && (
                <DropdownItem
                  danger
                  onClick={() => onDelete(user)}
                  data-test-id={`AccountsUserRow.${user.login}.dropdown.Delete`}
                >
                  {t('AccountsUserRow:Delete')}
                </DropdownItem>
              )}
            </DropdownMenu>
          </DropdownButton>
        )}
      </td>
    </tr>
  );
};

AccountsUserRow.displayName = 'AccountsUserRow';

export default React.memo(AccountsUserRow);
