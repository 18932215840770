import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import AccountsAndUsersService from '@just-ai/api/dist/services/AccountsAndUsersService';
import { Button, Icon, Label, Spinner, useForceUpdate, usePromiseProcessing } from '@just-ai/just-ui';

import localize, { t } from 'localization';
import { axios, isAxiosError } from 'pipes/functions';
import { appStores } from 'store';

import { useAppContext } from 'components/AppContext';
import GroupedSelector, { OptionsGroup } from 'components/GroupedSelector';
import { NoAccounts } from 'components/NoAccounts';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import TableSearchField from 'components/TableSearchField';
import { toOption } from 'helpers/toOption';
import InnerPage from 'views/LayoutUi/InnerPage';

import AddUsersTable from './components/AddUsersTable';
import { addUsersLocalization } from './localization/addUsers.loc';

import styles from './styles.module.scss';

localize.addTranslations(addUsersLocalization);

const accountsAndUsersService = new AccountsAndUsersService(axios);
const accountsadminService = new AccountsadminService(axios);

export const AddUsers = () => {
  const { addAlert, appConfig } = useAppContext();
  const forceUpdate = useForceUpdate();
  const pageParams = useParams<{ accountId: string }>();
  const history = useHistory();
  const { users, loadUsers, usersPaging, groupsAndRoles, getGroupAndRoles, usersOrAdminsLoading } = {
    ...appStores.Users(store => ({
      users: store.users.records,
      loadUsers: store.loadUsersWithExcludedAccount,
      usersOrAdminsLoading: store.usersOrAdminsLoading,
      usersPaging: store.users.paging,
      loadRoles: store.loadRoles,
      roles: store.roles,
    })),
    ...appStores.Accounts(state => ({
      groupsAndRoles: state.groupsAndRoles,
      getGroupAndRoles: state.getGroupAndRoles,
    })),
  };
  const [findAccountUsersState, findAccountUsersApi] = usePromiseProcessing(accountsadminService.getAccount);

  const [searchText, setSearchText] = useState('');
  const [roles, setRoles] = useState<string[]>([]);

  const { paginationInfo, changePageSize, changePage } = usePagination(
    usersPaging || { totalCount: 0, pageNum: 0, pageSize: 5 }
  );

  useEffect(() => {
    loadUsers({
      searchText: searchText,
      page: paginationInfo.pageNum,
      pageSize: paginationInfo.pageSize,
      excludeAccountId: parseInt(pageParams.accountId),
    });
  }, [loadUsers, pageParams.accountId, paginationInfo.pageNum, paginationInfo.pageSize, searchText]);

  useEffect(() => {
    if (!groupsAndRoles.length) {
      getGroupAndRoles(parseInt(pageParams.accountId));
    }
    findAccountUsersApi(parseInt(pageParams.accountId));
  }, [findAccountUsersApi, getGroupAndRoles, groupsAndRoles.length, pageParams.accountId]);

  const checkedUsers = useRef<Map<number, UserSearchRecord>>(new Map());

  const handleUsersCheckboxChange = useCallback(
    (userId: number) => {
      const userObj = users.find(user => user.id === userId);
      checkedUsers.current.has(userId)
        ? checkedUsers.current.delete(userId)
        : checkedUsers.current.set(userId, userObj!);
      checkedUsers.current = new Map(checkedUsers.current);
      forceUpdate();
    },
    [forceUpdate, users]
  );

  const groupsToOptions: OptionsGroup[] = useMemo(
    () =>
      groupsAndRoles.map(group => ({
        label: group.name,
        value: group.name,
        list: group.roles.map(role => toOption(role.name)),
      })),
    [groupsAndRoles]
  );

  const goToAccount = useCallback(() => {
    history.push(`/c/accounts/${pageParams.accountId}`);
  }, [history, pageParams.accountId]);

  const handleAdd = useCallback(async () => {
    const selectedUsers = checkedUsers.current;
    try {
      await accountsAndUsersService.addUsersToAccount(parseInt(pageParams.accountId), {
        roles,
        userIds: Array.from(selectedUsers.keys()),
      });
      addAlert({
        type: 'success',
        title: t('AddUsersPage:Alert:Title'),
        message:
          selectedUsers.size > 1
            ? t('AddUsersPage:Alert:AddedMultiple', {
                size: selectedUsers.size,
                account: findAccountUsersState.result?.name,
              })
            : t(`AddUsersPage:Alert:AddedOne`, {
                email: selectedUsers.values().next().value.email,
                account: findAccountUsersState.result?.name,
              }),
        time: Date.now(),
        showed: true,
      });
      checkedUsers.current = new Map();
      goToAccount();
    } catch (error) {
      if (isAxiosError(error)) {
        const errorMessage = error?.response?.data?.error;
        addAlert({
          type: 'error',
          message: t(`AddUsersPage:Alert:${errorMessage}`),
          time: Date.now(),
          showed: true,
        });
      }
    }
  }, [addAlert, findAccountUsersState.result?.name, goToAccount, pageParams.accountId, roles]);

  const selectedOverLimit = useMemo(() => {
    if (!appConfig?.invitationOptions.maxUsersPerInvitation) return false;
    return checkedUsers.current.size > appConfig?.invitationOptions.maxUsersPerInvitation;
  }, [appConfig?.invitationOptions.maxUsersPerInvitation, checkedUsers.current.size]);

  const buttonDisabled = !checkedUsers.current.size || !roles.length || selectedOverLimit;
  if (findAccountUsersState.loading) return <Spinner />;

  return (
    <InnerPage
      title={
        <div className='flex-column ai-start gap-3x'>
          <div
            className='flex-row font-color-secondary cursor-pointer gap-4'
            onClick={goToAccount}
            data-test-id='AddUserPage.goBackButton'
          >
            <Icon size='sm' name='farArrowLeft' style={{ display: 'flex' }} />
            <p className='mb-0 font-size-14 line-height-20'>{t('AddUserPage:Back')}</p>
          </div>
          <div className='flex-column  gap-2x mb-2'>
            <h1 className='flex-row gap-8'>
              {t('AddUserPage:Title', { accountName: findAccountUsersState.result?.name })}
            </h1>
            <p className='mb-0'>{t('AddUserPage:AccountId', { accountId: findAccountUsersState.result?.id })}</p>
          </div>
          <div>
            <p className='mb-0'>{t('AddUserPage:Info')}</p>
            <p className='mb-0'>{t('AddUserPage:Info:Remark')}</p>
          </div>
        </div>
      }
    >
      <div className={styles.AddUserPage__wrapper}>
        {usersOrAdminsLoading && <Spinner />}
        <div className='flex-column mb-5'>
          <Label>{t('AddUserPage:GroupSelector:Label')}</Label>
          <GroupedSelector groups={groupsToOptions} selected={roles} onChange={setRoles} fixedGroupWidth />
        </div>
        <p className='mb-3'>{t('AddUserPage:Table:Title')}</p>
        <TableSearchField
          value={searchText}
          onChange={setSearchText}
          placeholder={t('AddUserPage:Search:Placeholder')}
        />
        {users.length > 0 ? (
          <div>
            <AddUsersTable
              users={users}
              checkedUsers={checkedUsers.current}
              handleUserCheckboxChange={handleUsersCheckboxChange}
            />
            <div className={styles.AddUserPage__pagination}>
              <Pagination
                page={paginationInfo.pageNum}
                size={paginationInfo.pageSize}
                totalCount={paginationInfo.totalCount}
                changePage={changePage}
                changePageSize={changePageSize}
              />
            </div>
          </div>
        ) : (
          <NoAccounts />
        )}
        {selectedOverLimit && <small className='font-color-danger'>{t('AddUsersPage:Error:Limit')}</small>}
        <div className={styles.AddUserPage__footer}>
          <div className={styles.AddUserPage__chips}>
            <p className='mb-0'>{t('AddUsersPage:Selected')}</p>
            {checkedUsers.current.size ? (
              Array.from(checkedUsers.current.values()).map(selectedUser => (
                <div key={selectedUser.id} className={styles.AddUserPage__selectedUser}>
                  <span>{selectedUser.email || selectedUser.login}</span>
                  <Icon
                    name='farTimes'
                    color='secondary'
                    size='sm'
                    onClick={() => handleUsersCheckboxChange(selectedUser.id)}
                  />
                </div>
              ))
            ) : (
              <p className='mb-0'>0</p>
            )}
          </div>
          <Button color='primary' onClick={handleAdd} disabled={buttonDisabled} className={styles.AddUserPage__submit}>
            {t('AddUsersPage:Submit')}
          </Button>
        </div>
      </div>
    </InnerPage>
  );
};
