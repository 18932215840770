import { mapKeys } from 'lodash';
import { commonLocalization } from './decomposed/common.loc';

import { appStores } from '../store';

const Localize = require('localize');
require('decliner/dist/decliner.min');
const radix = 10;

const localize = new Localize({ ...commonLocalization });

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    // eslint-disable-next-line no-prototype-builtins
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

localize.checkExistKey = key => {
  return key !== localize.translate(key);
};

localize.throwOnMissingTranslation(false);

localize.decliner = (arr, count) => {
  return arr.decline(parseInt(count, radix));
};

/**
 * Возвращает значение конкотенированный строки состоящей из baseKey + значение из вызова функции Intl.PluralRules.select -> одно из [ zero, one, two, few, many, other ]
 *
 * @param {string} baseKey - localization string without [ zero, one, two, few, many, other ]
 * @param {number} numberValue
 * @returns {string} - `${baseKey}_${pluralRight}`
 */
export const tWithPlural = (baseKey, numberValue) => {
  const pluralValue = new Intl.PluralRules(
    Intl.PluralRules.supportedLocalesOf([localize.getLocale()], { localeMatcher: 'lookup' })?.[0] || 'en-US'
  );
  const pluralRight = pluralValue.select(numberValue);
  return t(`${baseKey}_${pluralRight}`, numberValue);
};

export function useLocalization() {
  return appStores.CurrentUser(state => state.lang);
}

export const { translate: t } = localize;
export default localize;
