import React, { useCallback, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import cn from 'classnames';
import { clamp } from 'lodash';
import { Icon, InputText, JustSelect } from '@just-ai/just-ui';

import { t } from 'localization';

import styles from './styles.module.scss';

const sizeOptions = ['5', '10', '20', '50', '100'].map(x => ({ value: x, label: x }));

interface PaginationProps {
  page: number;
  size: number;
  totalCount: number;
  changePage: (page: number) => void;
  changePageSize: (page: number) => void;
}
const Pagination = ({ page, size, changePageSize, totalCount, changePage }: PaginationProps) => {
  const [innerPage, setInnerPage] = useState(page);
  const onChangePageInner = useCallback((value: string) => setInnerPage(parseInt(value) - 1), []);
  const onChangePage = useCallback(
    (val?: number) => {
      const value = val ?? innerPage;
      if (page !== value) {
        changePage(value);
      }
    },
    [changePage, innerPage, page]
  );
  const onChangePageKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') onChangePage();
    },
    [onChangePage]
  );
  const onPageClick = useCallback(
    (data: { selected: number }) => {
      setInnerPage(data.selected);
      onChangePage(data.selected);
    },
    [onChangePage]
  );
  const onChangeSize = useCallback(
    (val: (string | number)[] | null) => {
      if (!val) return;
      const valNum = typeof val[0] === 'number' ? val[0] : parseInt(val[0]);
      if (valNum === size) return;
      changePageSize(valNum);
    },
    [changePageSize, size]
  );

  const pagesCount = size === 0 ? 0 : Math.ceil(totalCount / size);

  useEffect(() => {
    const clampedVal = clamp(innerPage, 0, pagesCount - 1);
    if (clampedVal !== innerPage) setInnerPage(clampedVal);
  }, [innerPage, pagesCount]);

  return (
    <div className={styles.Pagination}>
      <div>{t('Pagination:Founded', { size: totalCount })}</div>
      {pagesCount > 0 && (
        <>
          <ReactPaginate
            pageCount={pagesCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={onPageClick}
            forcePage={page}
            containerClassName={styles.Pagination_paginator}
            pageClassName={styles.Pagination_page}
            pageLinkClassName={styles.Pagination_page_link}
            breakClassName={styles.Pagination_page}
            breakLinkClassName={styles.Pagination_page_link}
            activeClassName={styles.Pagination_active}
            previousClassName={cn(styles.Pagination_previous, {
              [styles.disabled]: page === 0,
            })}
            nextClassName={cn(styles.Pagination_next, {
              [styles.disabled]: page === pagesCount - 1,
            })}
            nextLabel={<Icon name='farChevronRight' data-test-id='pagination-next' id='pagination-next' />}
            previousLabel={<Icon name='farChevronLeft' data-test-id='pagination-previous' id='pagination-previous' />}
          />
          <div className={styles.goToPageContainer}>
            <span>{t('Pagination:ToThePage')}</span>
            <InputText
              data-test-id='Pagination-page-input'
              type='number'
              value={(innerPage + 1).toString()}
              min={1}
              max={pagesCount}
              onChange={onChangePageInner}
              onKeyDown={onChangePageKeydown}
            />
          </div>
        </>
      )}

      <div className={styles.sizeContainer}>
        <span>{t('Pagination:ShowSize')}</span>
        <JustSelect
          value={size?.toString() || '0'}
          withoutSearch={true}
          options={sizeOptions}
          position='fixed'
          onChange={onChangeSize}
          listAutoPosition
          className={styles.sizeContainer__select}
          classNameForList={styles.SizeDropdownSelect}
          data-test-id='Pagination-page-size'
        />
      </div>
    </div>
  );
};

Pagination.displayName = 'Pagination';

export default React.memo(Pagination);
