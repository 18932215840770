import queryString from 'query-string';

import localization, { t } from 'localization';

import { BasePage, BasePropTypes, Error } from 'views/BasePage';

import { errorPageLocalization } from './localization/errorPage.loc';

localization.addTranslations(errorPageLocalization);

class State {
  loaded: boolean = true;
  errors: Error[] | [] = [];
  fetching: boolean = false;
}

class OauthPage extends BasePage<BasePropTypes, State> {
  state = new State();

  renderHead = () => {
    const { location } = this.props;
    const { appConfig } = this.context;

    const parsedParams = queryString.parse(location.search);
    const ccLink = `${window.location.protocol}//${appConfig.domains['cc'].domain}/c/acoounts`;

    return (
      <>
        <h3 className='mb-2'>{t(`ErrorPage:Error:${parsedParams.errorCode}`)}</h3>
        <p
          className='mb-0'
          dangerouslySetInnerHTML={{
            __html: t(`ErrorPage:Error:Message:${parsedParams.errorCode}`, {
              docsLink: t('ErrorPage:Error:Link'),
              ccLink,
            }),
          }}
          data-test-id='ErrorPage.ErrorMessage'
        ></p>
      </>
    );
  };
}

export default OauthPage;
