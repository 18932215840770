export const extensionPageLocalization = {
  'ExtensionPage:Title': {
    ru: 'Разрешите доступ к учетной записи {name} ({email})',
    eng: 'Allow access to {name} ({email})',
  },
  'ExtensionPage:Subtitle': {
    ru: 'Расширение JAICP для VS Code получит доступ к информации вашего профиля и сможет выполнять действия от вашего имени.',
    eng: 'The JAICP VS Code extension will have access to your profile information and will be able to perform actions on your behalf.',
  },
  'ExtensionPage:Agree': {
    ru: 'Разрешить',
    eng: 'Allow',
  },
  'ExtensionPage:Cancel': {
    ru: 'Отмена',
    eng: 'Cancel',
  },
};
