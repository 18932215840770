import React, { createRef, SyntheticEvent } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Button, InputText } from '@just-ai/just-ui/dist';

import { SendResetPasswordDto } from 'api/cloud/client';
import { t } from 'localization';
import { getDomainData, isAxiosError } from 'pipes/functions';

import PasswordResetService from 'service/PasswordResetService';
import { BasePage, Error } from 'views/BasePage';

const FIELDS = ['email'];

class ForgotPassword extends BasePage<any, any> {
  state = {
    errors: [],
    fetching: false,
    gRecaptchaResponse: null,
    success: false,
    loaded: true,
  };

  form = {
    email: createRef<HTMLInputElement>(),
    password: createRef<HTMLInputElement>(),
  };

  recaptchaInstance: any;

  PasswordResetService = new PasswordResetService();

  componentDidMount() {
    this.supportAddOnMessageListener();
  }

  validate = (sendResetPasswordObject: SendResetPasswordDto) => {
    const { errors } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: { args: { path: string } }[] = [];
    FIELDS.forEach((field: string) => {
      if (!sendResetPasswordObject[field as keyof SendResetPasswordDto]) {
        fieldErrors.push({
          args: {
            path: field,
          },
        });
      }
    });

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { appConfig } = this.context;
    const { email } = this.form;
    const {
      location: { search },
    } = this.props;

    const { product, redirectUrl } = getDomainData(search, appConfig?.domains);

    const sendResetPasswordObject: SendResetPasswordDto = {
      email: email.current?.value?.trim() || '',
      redirectUrl: redirectUrl as string,
    };
    if (product) sendResetPasswordObject.product = product;

    if (!this.validate(sendResetPasswordObject)) return;

    this.setState({
      fetching: true,
    });

    if (appConfig?.captcha?.enabled) {
      await this.executeCaptcha();
    }

    try {
      await this.PasswordResetService.sendResetPasswordEmail(sendResetPasswordObject, this.state.gRecaptchaResponse);
      this.setState({
        success: true,
        fetching: false,
        errors: [],
      });
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];

        this.resetCaptcha();
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  renderInputs = () => {
    const { success } = this.state;
    return (
      !success && (
        <div
          className={classNames('form-row forgot-password-email', {
            'with-error': Boolean(this.renderFieldError('email')),
          })}
        >
          <label htmlFor='email'>{t(`Register: field email label`)}</label>
          <InputText name='email' id='email' innerRef={this.form.email} placeholder='user@example.com' />
          {this.renderFieldError('email')}
        </div>
      )
    );
  };

  renderButtons = () => {
    const { success } = this.state;
    return (
      !success && (
        <div className='base-page_formarea-buttons forgot-password-buttons'>
          <Button color='primary'>{t('ForgotPassword: submit button text')}</Button>
        </div>
      )
    );
  };

  renderHead = () => {
    const { success } = this.state;
    return (
      <div className='base-page_formarea-head forgot-password-head'>
        <h1>{t(success ? 'ForgotPassword: form header success text' : 'ForgotPassword: form header text')}</h1>
      </div>
    );
  };

  renderFooter = () => {
    const { appConfig } = this.context;
    const { location } = this.props;

    return (
      <div className='base-page_formarea-footer'>
        <p>
          <NavLink className='mr-4' to={`/c/login${location?.search}`}>
            {t('Register: login link')}
          </NavLink>
          {appConfig.registration.enabled && (
            <NavLink to={`/c/register${location?.search}`}>{t('Login: register link')}</NavLink>
          )}
        </p>
      </div>
    );
  };
}

export default ForgotPassword;
