import React, { useCallback, useEffect, useState } from 'react';
import { IconButtonProps, InputGroup, InputText, useDebounce } from '@just-ai/just-ui';

import styles from './styles.module.scss';

interface TableSearchFieldProps {
  onChange: (test: string) => void;
  value: string;
  placeholder?: string;
}

const TableSearchField = ({ value, onChange, placeholder }: TableSearchFieldProps) => {
  const [innerSearchText, setInnerSearchText] = useState(value);
  const searchText = useDebounce(innerSearchText, 500);

  useEffect(() => {
    onChange(searchText);
  }, [onChange, searchText]);

  const handleClearInput = useCallback(() => {
    setInnerSearchText('');
  }, []);

  const getIconsArr = useCallback(() => {
    const appendInputIcons: IconButtonProps[] = [{ color: 'secondary', name: 'farSearch' }];

    if (value.length > 0) {
      appendInputIcons.unshift({ color: 'secondary', name: 'faTimesCircle', onClick: handleClearInput });
    }
    return appendInputIcons;
  }, [handleClearInput, value.length]);

  return (
    <InputGroup AppendInner={getIconsArr()} className={styles.TableSearchField}>
      <InputText
        placeholder={placeholder}
        name='searchInput'
        onChange={setInnerSearchText}
        value={innerSearchText}
        data-test-id='usersPage.searchUserForm.userNameInput'
      />
    </InputGroup>
  );
};

TableSearchField.displayName = 'TableSearchField';

export default React.memo(TableSearchField);
