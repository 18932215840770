export const registerLocalization = {
  'Register: form header text': {
    eng: 'Registration',
    ru: 'Регистрация',
    pt: 'Registro',
    cn: 'Registration',
  },
  'Register: field name label': {
    eng: 'Full name',
    ru: 'Имя и фамилия',
    pt: 'Nome completo',
    cn: 'Full name',
  },
  'Register: field email label': {
    eng: 'Email',
    ru: 'Email',
    pt: 'E-mail',
    cn: 'Email',
  },
  'Register: field password label': {
    eng: 'Password',
    ru: 'Пароль',
    pt: 'Senha',
    cn: 'Password',
  },
  'Register: submit button text': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
    cn: 'Continue',
  },
  'Register: I agree with': {
    eng: 'I agree with ',
    ru: 'Я соглашаюсь c ',
    pt: 'Concordo com ',
    cn: '我同意',
  },
  'Register: ToS link text': {
    eng: 'Terms of Service',
    ru: 'условиями использования',
    pt: 'Termos de Serviço',
    cn: 'Terms of Service',
  },
  'Register: policy link text': {
    eng: 'Privacy Policy',
    ru: 'политикой обработки персональных данных',
    pt: 'Política de Privacidade',
    cn: 'Privacy Policy',
  },
  'Register: required field': {
    eng: 'Required field',
    ru: 'Обязательное поле',
    pt: 'Campo obrigatório',
    cn: 'Required field',
  },
  'Register: password LOW': {
    eng: 'The minimum password length must be 8 characters. We recommend you to use at least 1 number, 1 lowercase, and 1 uppercase letter',
    ru: 'Длина пароля должна быть не менее 8 символов. Мы рекомендуем использовать в пароле как минимум одну цифру, одну строчную и одну прописную буквы',
    pt: 'O comprimento mínimo da senha deve ser de 8 caracteres. Recomendamos usar pelo menos 1 número, 1 letra minúscula e 1 letra maiúscula',
    cn: 'The minimum password length must be 8 characters. We recommend you to use at least 1 number, 1 lowercase, and 1 uppercase letter',
  },
  'Register: password MEDIUM': {
    eng: 'The minimum password length must be 10 characters. The password must contain at least 1 number, 1 lowercase, and 1 uppercase letter',
    ru: 'Длина пароля должна быть не менее 10 символов. Пароль должен содержать как минимум одну цифру, одну строчную и одну прописную буквы',
    pt: 'O comprimento mínimo da senha deve ser de 10 caracteres. A senha deve conter pelo menos 1 número, 1 letra minúscula e 1 letra maiúscula',
    cn: 'The minimum password length must be 10 characters. The password must contain at least 1 number, 1 lowercase, and 1 uppercase letter',
  },
  'Register: password HIGH': {
    eng: 'The minimum password length must be 12 characters. The password must contain at least 1 number, 1 lowercase, 1 uppercase letter, and one of the special characters *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
    ru: 'Длина пароля должна быть не менее 12 символов. Пароль должен содержать как минимум одну цифру, одну строчную, одну прописную буквы и один из специальных символов *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
    pt: 'O comprimento mínimo da senha deve ser de 12 caracteres. A senha deve conter pelo menos 1 número, 1 letra minúscula, 1 letra maiúscula e um dos caracteres especiais *.!@#$%^&(){}[]:;<>,.?/~_+-= |\\',
    cn: 'The minimum password length must be 12 characters. The password must contain at least 1 number, 1 lowercase, 1 uppercase letter, and one of the special characters *.!@#$%^&(){}[]:;<>,.?/~_+-=|\\',
  },
  'Register: accept text': {
    eng: 'Is this your country?',
    ru: 'Это действительно ваша страна?',
    pt: 'Este é o seu país?',
    cn: 'Is this your country?',
  },
  'Register: accept yes': {
    eng: 'Yes',
    ru: 'Да',
    pt: 'Sim',
    cn: 'Yes',
  },
  'Register: accept no': {
    eng: 'No',
    ru: 'Нет',
    pt: 'Não',
    cn: 'No',
  },
  'Register: select country placeholder': {
    eng: 'Select country',
    ru: 'Выберите страну',
    pt: 'Selecionar país',
    cn: 'Select country',
  },
  'Register: Enter captcha': {
    eng: 'Prove you are not a robot',
    ru: 'Подтвердите, что вы не робот',
    pt: 'Prove que você não é um robô',
    cn: 'Prove you are not a robot',
  },
  'Register: privacy policy link en': {
    eng: 'https://just-ai.com/eng/docs/pp.pdf',
    ru: 'https://just-ai.com/eng/docs/pp.pdf',
    pt: 'https://just-ai.com/eng/docs/pp.pdf',
    cn: 'https://just-ai.com/eng/docs/pp.pdf',
  },
  'Register: privacy policy link ru': {
    eng: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    ru: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    pt: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    cn: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
  },
  'Register: terms of use link en': {
    eng: 'https://just-ai.com/eng/docs/tos.pdf',
    ru: 'https://just-ai.com/eng/docs/tos.pdf',
    pt: 'https://just-ai.com/eng/docs/tos.pdf',
    cn: 'https://just-ai.com/eng/docs/tos.pdf',
  },
  'Register: terms of use link ru': {
    eng: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
    ru: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
    pt: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
    cn: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
  },
  'Register: have account': {
    eng: 'Already have an account? ',
    ru: 'Уже есть аккаунт? ',
    pt: 'Já tem uma conta? ',
    cn: 'Already have an account? ',
  },
  'Register: login link': {
    eng: 'Sign in',
    ru: 'Войти',
    pt: 'Fazer login',
    cn: 'Sign in',
  },
  'Register: register with': {
    eng: 'Sign up with',
    ru: 'Регистрация с помощью',
    pt: 'Inscreva-se com',
    cn: 'Sign up with',
  },
  'Register: field country label': {
    eng: 'Country of permanent residence',
    ru: 'Страна постоянного проживания',
    pt: 'País de residência permanente',
    cn: 'Country of permanent residence',
  },
};
