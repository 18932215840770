var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Icon } from '../Icon';
import memoize from 'memoizerific';
import { DefaultListItem } from './JustSelectItem';
import cn from 'classnames';
const DefaultSelectedItemWrapper = ({ onDelete, label, children, onClick }) => {
    return (_jsxs("div", { className: 'just-select-selected-item', "data-test-id": 'default-just-select-selected-item', role: 'selected-item', children: [_jsx("div", { className: cn('just-select-selected-item-label-name', onClick && 'cursor-pointer'), "data-test-id": `default-just-select-selected-item-name-${label}`, onClick: onClick, children: children }), _jsx("div", { className: 'just-select-selected-item-delete', onClick: onDelete, children: _jsx(Icon, { name: 'fasTimes' }) })] }));
};
const createJustSelectSelectedItem = (Component, _a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const SelectedItem = React.memo(props => (_jsx(DefaultSelectedItemWrapper, { onDelete: props.onDelete, label: props.label, onClick: props.onClick, children: _jsx(Component, Object.assign({}, rest, { label: props.label })) })));
    SelectedItem.displayName = `JustSelectedChild_${Component.displayName}`;
    return SelectedItem;
};
export const getSelectedItem = memoize(1)((SelectedElement = DefaultListItem, props = {}) => {
    return createJustSelectSelectedItem(SelectedElement, props);
});
