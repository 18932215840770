import { create } from 'zustand';
import { JustSessionUserData } from '@just-ai/api/dist/generated/Accountsadmin';

import { UserDataDto } from 'api/cloud/client';

export type CurrentUserState = {
  user?: JustSessionUserData;
  setCurrentUser(user: UserDataDto | null): void;
  lang: string;
  setLanguage: (lang: string) => void;
};

export const useCurrentUser = create<CurrentUserState>(set => ({
  user: undefined,
  lang: 'eng',
  setCurrentUser(user) {
    return set({ user: user?.userData });
  },
  setLanguage(lang: string) {
    return set({ lang });
  },
}));
