export const headerLocalization = {
  'CloudMenu: aimylogic': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
    cn: 'Aimylogic',
  },
  'CloudMenu: aimyvoice': {
    eng: 'Aimyvoice',
    ru: 'Aimyvoice',
    cn: 'Aimyvoice',
  },
  'CloudMenu: caila': {
    eng: 'Caila NLP Platform',
    ru: 'Caila NLP Platform',
    cn: 'Caila NLP Platform',
  },
  'CloudMenu: cc': {
    eng: 'Conversational Cloud',
    ru: 'Conversational Cloud',
    cn: 'Conversational Cloud',
  },
  'CloudMenu: jaicp': {
    eng: 'JAICP',
    ru: 'JAICP',
    cn: 'JAICP',
  },
  'CloudMenu: aimychat': {
    eng: 'Aimychat',
    ru: 'Aimychat',
  },
  'Header: profile': {
    eng: 'Profile',
    ru: 'Профиль',
    cn: 'Profile',
  },
  'Header: accounts': {
    eng: 'Accounts',
    ru: 'Аккаунты',
    cn: 'Accounts',
  },
  'Header: users': {
    eng: 'Users',
    ru: 'Пользователи',
    cn: 'Users',
  },
  'Header: admins': {
    eng: 'Administrators',
    ru: 'Администраторы',
    cn: 'Administrators',
  },
  'Header: features': {
    eng: 'Feature settings',
    ru: 'Настройки фич',
    cn: 'Feature settings',
  },
  'Header: logout': {
    eng: 'Sign out',
    ru: 'Выйти',
    cn: 'Sign out',
  },
  'CloudMenu: Tovie DialogStudio': {
    eng: 'Tovie DialogStudio',
    ru: 'Tovie DialogStudio',
    pt: 'Tovie DialogStudio',
  },
  'CloudMenu: Tovie Voice': {
    eng: 'Tovie Voice',
    ru: 'Tovie Voice',
    pt: 'Tovie Voice',
  },
  'CloudMenu: Tovie NLP': {
    eng: 'Caila',
    ru: 'Caila',
    pt: 'Caila',
  },

  'CloudMenu: Tovie Cloud': {
    eng: 'Tovie Cloud',
    ru: 'Tovie Cloud',
    pt: 'Tovie Cloud',
  },
  'CloudMenu: Tovie Agent': {
    eng: 'Tovie Agent',
    ru: 'Tovie Agent',
    pt: 'Tovie Agent',
  },
  'CloudMenu: Tovie Platform': {
    eng: 'Tovie Platform',
    ru: 'Tovie Platform',
    pt: 'Tovie Platform',
  },
};
