import { useCallback, useMemo, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
export function useQueryParam(key, initial) {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const value = useMemo(() => new URLSearchParams(search).get(key), [search, key]);
    const setValue = useCallback((val) => {
        const valStr = val.toString();
        const params = new URLSearchParams(search);
        valStr === '' ? params.delete(key) : params.set(key, val.toString());
        history.replace({ pathname, search: params.toString() });
    }, [history, key, pathname, search]);
    useEffect(() => {
        if (value || !initial)
            return;
        const urlSearchParams = new URLSearchParams(search);
        urlSearchParams.set(key, initial);
        history.replace({ pathname, search: urlSearchParams.toString() });
        // eslint-disable-next-line
    }, [key]);
    return [value, setValue];
}
