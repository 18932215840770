import { useCallback, useMemo, useRef, useState } from 'react';
import Cookies from 'universal-cookie';
import { CaptchaOptions } from '@just-ai/api/dist/generated/Accountsadmin';

import { getUserLanguage } from 'pipes/functions';

import Recaptcha, { isCaptchaReady } from './index';

const cookies = new Cookies();

export function useCaptcha(options?: CaptchaOptions) {
  const recaptchaInstance = useRef<Recaptcha>(null);
  const captchaReadyInterval = useRef<NodeJS.Timer>();
  const captchaPromiseResolve = useRef<Function | null>(null);
  const [gRecaptchaResponse, setGRecaptchaResponse] = useState<string>('');
  const lang = getUserLanguage();

  const executeCaptcha = useCallback(async () => {
    if (!options?.enabled) return '';
    if (cookies.get('CAPTCHA_BYPASS_TOKEN')) return '';
    await new Promise<void>(res => {
      captchaReadyInterval.current = setInterval(() => {
        if (!isCaptchaReady()) return;
        captchaReadyInterval.current && clearInterval(captchaReadyInterval.current);
        res();
      }, 1000);
    });

    if (recaptchaInstance?.current) {
      recaptchaInstance.current.execute();
      return await new Promise<string>(res => {
        captchaPromiseResolve.current = res;
      });
    }
    return '';
  }, [options?.enabled]);

  const verifyCallback = useCallback((response: string) => {
    setGRecaptchaResponse(response);
    if (!captchaPromiseResolve.current) return;
    captchaPromiseResolve.current(response);
    captchaPromiseResolve.current = null;
  }, []);

  const resetCaptcha = useCallback(() => {
    if (!recaptchaInstance?.current) return;
    recaptchaInstance.current.reset();
    setGRecaptchaResponse('');
  }, []);

  const node = useMemo(() => {
    if (!options?.enabled || !options.siteKey) return null;
    return (
      <Recaptcha
        data-test-id='BasePage.recaptcha'
        ref={recaptchaInstance}
        sitekey={options.siteKey}
        className='captcha'
        size='invisible'
        verifyCallback={verifyCallback}
        expiredCallback={resetCaptcha}
        hl={lang.slice(0, 2).toUpperCase()}
      />
    );
  }, [options?.enabled, options?.siteKey, lang, verifyCallback, resetCaptcha]);

  return { node, executeCaptcha, resetCaptcha };
}
