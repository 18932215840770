import cn from 'classnames';
import { InviteUsersResponse } from '@just-ai/api/dist/generated/Accountsadmin';

import { t } from 'localization';

import { pluralize } from 'helpers/pluralize';

import styles from './styles.module.scss';

export const ResponseField = ({ responseFields }: { responseFields: InviteUsersResponse }) => {
  return (
    <>
      {Object.entries(responseFields).map(([field, emails]: [string, string[]]) => {
        const isError = ['failedInvalidEmails', 'failedMaxUsersReached', 'failedRegistrationDisabled'].includes(field);
        if ((Array.isArray(emails) && !emails.length) || !field.startsWith('failed')) return null;
        return (
          <div
            className={cn(styles.ResponseField__wrapper, styles[isError ? 'error' : 'info'])}
            key={field}
            data-test-id='InviteToProductForm.ResponseField'
          >
            <p className='mb-0'>
              {t(`InviteToProductForm:ResponseField:${field}`, {
                maxUsersPerAccount: responseFields['maxUsersPerAccount'] || 0,
                users: t(
                  `InviteToProductForm.ResponseField:Users:${pluralize(responseFields['maxUsersPerAccount'] || 0)}`
                ),
              })}
            </p>
            <ul className={styles.ResponseField__list}>
              {emails?.map(email => (
                <li key={email}>{email}</li>
              ))}
            </ul>
          </div>
        );
      })}
    </>
  );
};
