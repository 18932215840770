import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Icon, JustSelect, Label } from '@just-ai/just-ui';

import localization, { t } from 'localization';

import { useAppContext } from 'components/AppContext';
import { AccountsTabs } from 'views/AccountDetail';
import InnerPage from 'views/LayoutUi/InnerPage';

import { invitePageLocalization } from './localization/invitePage.loc';

import styles from './styles.module.scss';

localization.addTranslations(invitePageLocalization);

export const InvitePage = () => {
  const history = useHistory();
  const pageParams = useParams<{ accountId: string }>();
  const { appConfig } = useAppContext();
  const [domain, setDomain] = useState<string>();

  const goBack = useCallback(() => {
    history.push(`/c/accounts/${pageParams.accountId}?activeTab=${AccountsTabs.INVITES}`);
  }, [history, pageParams.accountId]);

  const getDomainsForInvite = useCallback(() => {
    if (!appConfig?.domains) return;
    return Object.values(appConfig?.domains)
      .filter(domainData => domainData.invitationAvailable)
      .map(availableDomains => ({
        label: availableDomains.appTitle
          ? `${availableDomains.appTitle} (${availableDomains.domain})`
          : availableDomains.domain,
        value: availableDomains.domain,
      }));
  }, [appConfig?.domains]);

  const handleDomainChange = useCallback(value => {
    setDomain(value[0]);
  }, []);

  const handleSubmitButton = useCallback(() => {
    history.push(`/c/accounts/${pageParams.accountId}/invite/${domain}`);
  }, [domain, history, pageParams.accountId]);

  return (
    <InnerPage
      title={
        <div className='flex-column ai-start gap-3x'>
          <div
            className='flex-row font-color-secondary cursor-pointer gap-4'
            onClick={goBack}
            data-test-id='InvitePage.goBackButton'
          >
            <Icon size='sm' name='farArrowLeft' style={{ display: 'flex' }} />
            <p className='mb-0 font-size-14 line-height-20'>{t('InvitePage:Back')}</p>
          </div>
          <div className='flex-column  gap-2x'>
            <h1 className='flex-row gap-8'>{t('InvitePage:Title')}</h1>
          </div>
        </div>
      }
      className={styles.InvitePage__wrapper}
    >
      <div className={styles.InvitePage}>
        <div>
          <Label>{t('InvitePage:ChooseDomain')}</Label>
          <JustSelect
            options={getDomainsForInvite()}
            onChange={handleDomainChange}
            fullWidth
            data-test-id='InvitePage.ProductSelect'
          />
          <small>{t('InvitePage:Info')}</small>
        </div>
        <Button
          color='primary'
          onClick={handleSubmitButton}
          className={styles.InvitePage__button}
          disabled={!domain}
          data-test-id='InvitePage.submit'
        >
          {t('InvitePage:Submit')}
        </Button>
      </div>
    </InnerPage>
  );
};
