import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Total } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import {
  Button,
  generateUniqId,
  Icon,
  Spinner,
  Tabs,
  usePromiseProcessing,
  usePromisifyComponent,
  useQueryParam,
} from '@just-ai/just-ui';

import localization, { t } from 'localization';
import { axios } from 'pipes/functions';
import { appStores } from 'store';

import isAccess, { isInternal } from 'helpers/isAccessFunction';
import InnerPage from 'views/LayoutUi/InnerPage';

import { accountsDetailLocalization } from './localization/accountsDetail.loc';
import RenameAccountModal from './modals/RenameAccountModal';
import AccountUsers from './tabs/AccountUsers';
import Invitations from './tabs/Invitations';

localization.addTranslations(accountsDetailLocalization);

export const ADMIN_USERS_DETAIL_TAB = 'activeTab';

export enum AccountsTabs {
  USERS = 'USERS',
  INVITES = 'INVITES',
}
const getTabs = () => [
  { name: t('AccountDetail:Tabs:Users'), value: AccountsTabs.USERS, dataTestId: 'AccountDetail.UsersTab' },
  { name: t('AccountDetail:Tabs:Invitations'), value: AccountsTabs.INVITES, dataTestId: 'AccountDetail.InvitationTab' },
];
const tabsContent: Record<AccountsTabs, React.ComponentType<{ account: Total }>> = {
  [AccountsTabs.USERS]: AccountUsers,
  [AccountsTabs.INVITES]: Invitations,
};

const accountsadminService = new AccountsadminService(axios);

const AccountDetail = () => {
  const pageParams = useParams<{ accountId: string }>();
  const history = useHistory();
  const { getGroupAndRoles, currentUser } = {
    ...appStores.Accounts(store => ({
      getGroupAndRoles: store.getGroupAndRoles,
    })),
    ...appStores.CurrentUser(store => ({
      currentUser: store.user,
    })),
  };

  const [tabs] = useState(() => getTabs());
  const [activeTab, setActiveTab] = useQueryParam(ADMIN_USERS_DETAIL_TAB, AccountsTabs.USERS);
  const [invitationCmpKey, setInvitationCmpKey] = useState(() => generateUniqId());

  const [findAccountUsersState, findAccountUsersApi] = usePromiseProcessing(accountsadminService.getAccount);
  const accountInfo = findAccountUsersState.result;

  useEffect(() => {
    if (!pageParams.accountId) return;
    findAccountUsersApi(parseInt(pageParams.accountId));
    getGroupAndRoles(parseInt(pageParams.accountId));
  }, [findAccountUsersApi, getGroupAndRoles, pageParams.accountId]);

  const goBack = useCallback(() => history.push('/c/accounts'), [history]);

  const [renameAccountModalNode, callRenameAccountModal] = usePromisifyComponent(
    (resolve, reject, opened) => (
      <RenameAccountModal isOpen={opened} onCancel={reject} onSubmit={resolve} account={accountInfo} />
    ),
    {
      onResolve: () => findAccountUsersApi(parseInt(pageParams.accountId)),
    },
    [accountInfo?.name]
  );

  const handleInviteClick = useCallback(() => {
    history.push(`/c/accounts/${pageParams?.accountId}/invite`);
  }, [history, pageParams?.accountId]);

  const goToAddPage = useCallback(() => {
    history.push(`/c/accounts/${accountInfo?.id}/add`);
  }, [accountInfo?.id, history]);

  const TabCmp = tabsContent[activeTab as AccountsTabs] ?? null;
  return (
    <InnerPage
      title={
        <div className='flex-column ai-start gap-3x'>
          <div
            className='flex-row font-color-secondary cursor-pointer gap-4'
            onClick={goBack}
            data-test-id='AccountDetailPage.goBackButton'
          >
            <Icon size='sm' name='farArrowLeft' style={{ display: 'flex' }} />
            <p className='mb-0 font-size-14 line-height-20'>{t('AccountDetail:Back')}</p>
          </div>
          <div className='flex-column  gap-2x'>
            <h1 className='flex-row gap-8'>
              <span data-test-id='AccountDetailPage.AccountName'>
                {accountInfo?.name || <Spinner inline size='sm' />}
              </span>
              {accountInfo?.ownerId === currentUser?.userId && (
                <Icon
                  name='farPen'
                  size='md'
                  color='secondary'
                  className='flex-row cursor-pointer'
                  onClick={callRenameAccountModal}
                  data-test-id='AccountDetailPage.RenameAccount'
                />
              )}
            </h1>
            {accountInfo?.id && (
              <div className='font-color-secondary font-size-14 line-height-20 font-weight-400'>
                {t('AccountDetail:AccountId', { id: accountInfo?.id })}
              </div>
            )}
          </div>
        </div>
      }
      headerRightSlot={
        <div className='flex-row jc-end h-100 gap-8'>
          {isInternal() && (
            <Button color='secondary' outline data-test-id='AccountDetail.AddUser' onClick={goToAddPage}>
              {t('AccountDetail:Add')}
            </Button>
          )}
          {(isAccess('ROLES_WRITE') || isInternal()) && (
            <Button color='primary' data-test-id='AccountDetail.InviteUser' onClick={handleInviteClick}>
              {t('AccountDetail:Invite')}
            </Button>
          )}
        </div>
      }
    >
      {findAccountUsersState.loading && <Spinner />}
      <Tabs tabs={tabs} onChange={setActiveTab} activeTab={activeTab as AccountsTabs} />
      {TabCmp && accountInfo && (
        <TabCmp account={accountInfo} key={activeTab === AccountsTabs.INVITES ? invitationCmpKey : 'Users'} />
      )}
      {renameAccountModalNode}
    </InnerPage>
  );
};

export default React.memo(AccountDetail);
