export const groupedSelectorLocalization = {
  'GroupedSelector:Field:Roles:Groups:Placeholder': {
    eng: 'Group',
    ru: 'Группа',
  },
  'GroupedSelector:Field:Roles:Placeholder': {
    eng: 'Roles',
    ru: 'Роли',
  },
  'GroupedSelector:Field:Roles:Add': {
    eng: 'Add role group',
    ru: 'Добавить группу ролей',
  },
};
