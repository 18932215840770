var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AccountManagementEndpointApi, RolesEndpointApi, UserManagementEndpointApi, } from '../../generated/Accountsadmin';
class AccountsAndUsersService {
    constructor(axios) {
        this.findAccountsByFilterAsSadmin = (filterData) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.findAccountsByFilterAsSadmin(filterData)).data;
        });
        this.createAccountAsSadmin = (data) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.createAccountAsSadmin(data)).data;
        });
        this.getAccountAsSadmin = (id) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.getAccountAsSadmin(id)).data;
        });
        this.updateAccountAsSadmin = (accountId, data) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.updateAccountAsSadmin(accountId, data)).data;
        });
        this.findUsersByFilterAsSadmin = (_a) => __awaiter(this, [_a], void 0, function* ({ page, pageSize, searchText, excludeAccountId, excludeAccountByInvitations, enabled, }) {
            return (yield this.UserManagementEndpointApi.findUsersByFilterAsSadmin(page, pageSize, searchText, false, enabled, false, true, excludeAccountId, excludeAccountByInvitations)).data;
        });
        this.findInternalUsersByFilterAsSadmin = (_b) => __awaiter(this, [_b], void 0, function* ({ page, pageSize, searchText, }) {
            return (yield this.UserManagementEndpointApi.findUsersByFilterAsSadmin(page, pageSize, searchText, true, true, false)).data;
        });
        this.createUserAsSadmin = (data) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.UserManagementEndpointApi.createUserAsSadmin(data)).data;
        });
        this.getUserAsSadmin = (userId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.UserManagementEndpointApi.getUserAsSadmin(userId)).data;
        });
        this.updateUserAsSadmin = (userId, data) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.UserManagementEndpointApi.updateUserAsSadmin(userId, data)).data;
        });
        this.getTariffsForSearch = () => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.getTariffsForSearch()).data;
        });
        this.bulkEditSelectedAccountsAsSadmin = (accountsBulkEditSelectedRequestDto) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto)).data;
        });
        this.bulkEditAllAccountsAsSadmin = (accountsBulkEditAllRequestDto) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.AccountManagementEndpointApi.bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto)).data;
        });
        this.getInternalRoles = () => __awaiter(this, void 0, void 0, function* () {
            const { data } = yield this.RolesEndpointApi.getInternalRoles();
            return data.filter((role) => role.editable);
        });
        this.getRoleGroups = (accountId) => __awaiter(this, void 0, void 0, function* () {
            return (yield this.RolesEndpointApi.getRoleGroupsAvailableToAccount(accountId)).data;
        });
        this.addUsersToAccount = (accountId, accountsToAdd) => __awaiter(this, void 0, void 0, function* () {
            return yield this.AccountManagementEndpointApi.addUsersToAccount(accountId, accountsToAdd);
        });
        this.AccountManagementEndpointApi = new AccountManagementEndpointApi({}, AccountsAndUsersService.BASE_PATH, axios);
        this.UserManagementEndpointApi = new UserManagementEndpointApi({}, AccountsAndUsersService.BASE_PATH, axios);
        this.RolesEndpointApi = new RolesEndpointApi({}, AccountsAndUsersService.BASE_PATH, axios);
    }
}
AccountsAndUsersService.BASE_PATH = '';
export default AccountsAndUsersService;
