import { useState, useEffect } from 'react';
export const useDebounce = (state, delay, callback) => {
    const [debouncedState, setDebouncedState] = useState(state);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedState(state);
            if (callback) {
                callback(state);
            }
        }, delay);
        return () => clearTimeout(handler);
    }, [state, delay, callback]);
    return debouncedState;
};
