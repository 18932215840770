export const externalSsoLocalization = {
  'ExternalSso:Footer:Email': {
    eng: 'Sign in without using SSO',
    ru: 'Войти без использования SSO',
  },
  'BasePage:BE-error external.sso.not.available email': {
    eng: 'This email is not configured for SSO. Please contact your administrator.',
    ru: 'Этот email не настроен для SSO. Пожалуйста, свяжитесь с вашим администратором.',
  },
  'BasePage:BE-error accountsadmin.authorization.user.can_login_only_with_external_sso': {
    eng: 'You are only allowed to sign in using SSO.',
    ru: 'Вам разрешен вход только с использованием SSO.',
  },
  'BasePage:BE-error accountsadmin.authorization.user.sso_user_without_email': {
    eng: 'Users without an email cannot use SSO to sign in.',
    ru: 'Пользователи без электронной почты не могут использовать SSO для входа.',
  },
  'BasePage:BE-error accountsadmin.user.email.can_not_be_changed_for_external_sso_users': {
    eng: 'You can only sign in via SSO, so you cannot change your email.',
    ru: 'Вам доступен вход только через SSO, поэтому вы не можете изменить свой email.',
  },
  'BasePage:BE-error accountsadmin.user.password.can_not_be_changed_for_external_sso_users': {
    eng: 'You can only sign in via SSO, so you cannot change your password.',
    ru: 'Вам доступен вход только через SSO, поэтому вы не можете изменить свой пароль.',
  },
};
