import React from 'react';
import { NavLink } from 'react-router-dom';
import { getAllowedRoutes } from 'routes';

import { t, useLocalization } from 'localization';
import { appStores } from 'store';

import './index.scss';

const Sidebar = () => {
  useLocalization();
  const invitations = appStores.Accounts(store => store.invitations);

  return (
    <div className='sidebar-wrapper'>
      <div className='sidebar'>
        {getAllowedRoutes().map(sidebarItem => (
          <div
            key={`sidebarItem_${sidebarItem.title}`}
            className='sidebar-item'
            data-test-id={`navigation.${sidebarItem.title}`}
          >
            <NavLink to={sidebarItem.link}>{t(`Sidebar: ${sidebarItem.title}`)}</NavLink>
            {sidebarItem.title === 'accounts' && invitations.length > 0 && <div className='notification-mark' />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
