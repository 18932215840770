import React from 'react';

export const google = (
  <svg width='17' height='18' viewBox='0 0 17 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16.4836 9.17496C16.4836 8.49814 16.4289 8.00425 16.3106 7.49207H8.61475V10.5469H13.132C13.041 11.306 12.5492 12.4493 11.4563 13.2176L11.4409 13.3198L13.8742 15.2129L14.0428 15.2298C15.591 13.7938 16.4836 11.681 16.4836 9.17496Z'
      fill='#4285F4'
    />
    <path
      d='M8.6515 17.1967C10.8823 17.1967 12.7551 16.47 14.123 15.2167L11.5157 13.2186C10.8181 13.7 9.88164 14.036 8.6515 14.036C6.4666 14.036 4.61219 12.6101 3.95115 10.6393L3.85425 10.6474L1.30386 12.6001L1.27051 12.6918C2.62918 15.362 5.42 17.1967 8.6515 17.1967Z'
      fill='#34A853'
    />
    <path
      d='M4.02438 10.6581C3.85099 10.1429 3.75063 9.5907 3.75063 9.02021C3.75063 8.44966 3.85099 7.89756 4.01526 7.38227L4.01067 7.27252L1.4435 5.26233L1.35951 5.30261C0.802824 6.42526 0.483398 7.68594 0.483398 9.02021C0.483398 10.3545 0.802824 11.6151 1.35951 12.7377L4.02438 10.6581Z'
      fill='#FBBC05'
    />
    <path
      d='M8.62002 3.96395C10.1649 3.96395 11.2069 4.62695 11.8011 5.181L14.123 2.92859C12.697 1.61167 10.8413 0.803345 8.62002 0.803345C5.4023 0.803345 2.62339 2.63794 1.27051 5.3081L3.93057 7.36072C4.59794 5.38988 6.44444 3.96395 8.62002 3.96395Z'
      fill='#EB4335'
    />
  </svg>
);

export const github = (
  <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_2667_888)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.25767 0.197083C3.84054 0.197083 0.258301 3.77885 0.258301 8.19745C0.258301 11.7316 2.55037 14.7303 5.72939 15.7887C6.12964 15.8619 6.27551 15.6148 6.27551 15.4027C6.27551 15.2126 6.26855 14.7097 6.26438 14.0423C4.03911 14.5255 3.5696 12.9697 3.5696 12.9697C3.20568 12.0454 2.68116 11.7993 2.68116 11.7993C1.95479 11.3033 2.73615 11.3131 2.73615 11.3131C3.53913 11.3696 3.96149 12.1377 3.96149 12.1377C4.6751 13.3601 5.83413 13.007 6.2899 12.8022C6.36257 12.2855 6.56934 11.9329 6.79771 11.733C5.02134 11.5307 3.1536 10.8446 3.1536 7.77902C3.1536 6.90532 3.46547 6.19171 3.97721 5.63233C3.89466 5.42998 3.62017 4.61667 4.05587 3.51508C4.05587 3.51508 4.72722 3.29999 6.25559 4.33479C6.89356 4.15749 7.57818 4.06909 8.25838 4.06565C8.93809 4.06843 9.62222 4.15753 10.2612 4.33479C11.7885 3.29999 12.4589 3.51508 12.4589 3.51508C12.8955 4.61667 12.621 5.42998 12.539 5.63233C13.0517 6.19171 13.3611 6.90532 13.3611 7.77902C13.3611 10.8524 11.4904 11.5287 9.70864 11.7266C9.99546 11.9736 10.2513 12.4618 10.2513 13.2084C10.2513 14.2775 10.2416 15.1404 10.2416 15.4027C10.2416 15.6168 10.386 15.8658 10.7916 15.7877C13.9682 14.7274 16.2583 11.7311 16.2583 8.19745C16.2583 3.77885 12.6761 0.197083 8.25747 0.197083'
        fill='#1B1817'
      />
    </g>
    <defs>
      <clipPath id='clip0_2667_888'>
        <rect width='16' height='16' fill='white' transform='translate(0.258301)' />
      </clipPath>
    </defs>
  </svg>
);
