import React, { Fragment, useMemo } from 'react';
import { RoleGroupColor, RoleGroupDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { Badge } from '@just-ai/just-ui';

import styles from './styles.module.scss';

type OptionsInnerGroup = RoleGroupDto & {
  options: string[];
};

function transformToGroupOptions(groups: RoleGroupDto[], selectedOptions: string[]): OptionsInnerGroup[] {
  const selectedOptionsSet = new Set(selectedOptions);
  const result = groups.map(group => {
    let options: string[] = [];
    selectedOptions.forEach(option => {
      const role = group.roles.find(role => role.name === option);
      if (role) {
        options.push(option);
        selectedOptionsSet.delete(option);
      }
    });
    return { ...group, options };
  });
  if (selectedOptionsSet.size > 0) {
    result.push({
      name: '',
      color: RoleGroupColor['Blue'],
      roles: [],
      options: Array.from(selectedOptionsSet),
    } as OptionsInnerGroup);
  }
  return result;
}

type GroupItem = { name: string };

type BadgeGroupsType = {
  groups: RoleGroupDto[];
  selected: GroupItem[];
};
const BadgeGroups = ({ groups, selected }: BadgeGroupsType) => {
  const groupsWithRoles = useMemo(
    () =>
      transformToGroupOptions(
        groups,
        selected.map(el => el.name)
      ).filter(group => group.options?.length),
    [groups, selected]
  );
  return (
    <div className={styles.BadgeGroups}>
      {groupsWithRoles.map(group => {
        return (
          <Fragment key={group.name}>
            {group.name ? <Badge pastel color={group.color} text={group.name} /> : <div></div>}
            <div className='flex-row gap-1x flex-wrap'>
              {group.options.map(role => (
                <Badge pastel key={role} color={group.color} text={role} />
              ))}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
export default React.memo(BadgeGroups);
