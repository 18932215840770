class ChatSupportController {
  init = (onMessage, email) => {
    if (Boolean(window.$crisp)) {
      window.$crisp.push([
        'on',
        'message:received',
        messages => {
          let crisp = document.getElementsByClassName('crisp-client');
          if (Boolean(crisp[0]) && !crisp[0].classList.contains('visible')) {
            onMessage && onMessage(messages);
          }
        },
      ]);
      email && window.$crisp.push(['set', 'user:email', email]);
    } else {
      throw Error('no crisp');
    }
  };

  close = () => {
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0])) {
      crisp[0].classList.remove('visible');
    }
  };

  show = () => {
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0]) && window.$crisp) {
      window.$crisp.push(['do', 'chat:show']);
      crisp[0].classList.add('visible');
      window.$crisp.push(['on', 'chat:closed', () => {}]);
    } else {
      throw Error('no crisp');
    }
  };

  toggle = onClose => {
    let crisp = document.getElementsByClassName('crisp-client');
    if (Boolean(crisp[0]) && window.$crisp) {
      crisp[0].classList.add('visible');
      window.$crisp.push(['do', 'chat:open']);
      window.$crisp.push([
        'on',
        'chat:closed',
        () => {
          this.close();
          onClose && onClose();
        },
      ]);
    }
  };
}

export default new ChatSupportController();
