import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Table } from 'reactstrap';
import { Total, UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Spinner, usePromiseProcessing, usePromisifyComponent } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { NoAccounts } from 'components/NoAccounts';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import TableSearchField from 'components/TableSearchField';
import DeleteUserModal from 'views/AccountDetail/modals/DeleteUserModal';
import EditUserModal from 'views/AccountDetail/modals/EditUserModal';

import AccountsUserRow from './AccountsUserRow';

import styles from './styles.module.scss';

const accountsadminService = new AccountsadminService(axios);

const COLS = ['email.id', 'name', 'groupsAndRoles'];

interface AccountUsersProps {
  account: Total;
}
const AccountUsers = ({ account }: AccountUsersProps) => {
  const pageParams = useParams<{ accountId: string }>();
  const { paginationInfo, changePageSize, changePage, changeTotalPages } = usePagination({
    totalCount: 0,
    pageNum: 0,
    pageSize: 10,
  });
  const [findAccountUsersState, findAccountUsersApi, optimisticFindAccountUsersApi] = usePromiseProcessing(
    async (searchText: string) => {
      if (!pageParams.accountId) return;
      return accountsadminService.findAccountUsers({
        accountId: parseInt(pageParams.accountId),
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
        searchText,
      });
    },
    {
      deps: [pageParams.accountId, paginationInfo.pageNum, paginationInfo.pageSize],
    }
  );

  useEffect(() => {
    const pageSize = findAccountUsersState.result?.paging.pageSize;
    if (pageSize !== undefined) changePageSize(pageSize);
    const totalCount = findAccountUsersState.result?.paging.totalCount;
    if (totalCount !== undefined) changeTotalPages(totalCount);
  }, [
    changePageSize,
    changeTotalPages,
    findAccountUsersState.result?.paging.pageSize,
    findAccountUsersState.result?.paging.totalCount,
  ]);

  const [searchText, setSearchText] = useState('');

  const onFilterChange = useCallback(
    (searchText: string) => {
      changePage(0);
      setSearchText(searchText);
    },
    [changePage]
  );

  useEffect(() => {
    findAccountUsersApi(searchText);
  }, [findAccountUsersApi, searchText]);

  const [selectedUser, setSelectedUser] = useState<UserSearchRecord>();

  const [editUserModalNode, callEditUserModal] = usePromisifyComponent((resolve, reject, opened) => (
    <EditUserModal isOpen={opened} onSubmit={resolve} onCancel={reject} account={account} user={selectedUser} />
  ));
  const onUserEdit = useCallback(
    (user: UserSearchRecord) => {
      setSelectedUser(user);
      callEditUserModal().finally(() => {
        setSelectedUser(undefined);
        optimisticFindAccountUsersApi(searchText);
      });
    },
    [callEditUserModal, optimisticFindAccountUsersApi, searchText]
  );
  const [deleteUserModalNode, callDeleteUserModal] = usePromisifyComponent((resolve, reject, opened) => (
    <DeleteUserModal isOpen={opened} account={account} onSubmit={resolve} onCancel={reject} user={selectedUser} />
  ));
  const onDeleteUser = useCallback(
    (user: UserSearchRecord) => {
      setSelectedUser(user);
      callDeleteUserModal().finally(() => {
        setSelectedUser(undefined);
        optimisticFindAccountUsersApi(searchText);
      });
    },
    [callDeleteUserModal, optimisticFindAccountUsersApi, searchText]
  );

  return (
    <div className={styles.AccountUsers}>
      {findAccountUsersState.loading && <Spinner />}

      <TableSearchField
        value={searchText}
        onChange={onFilterChange}
        placeholder={t('AccountUsers:Search:Placeholder')}
      />

      {findAccountUsersState.result?.records.length ? (
        <>
          <Table data-test-id='usersPage.userListTable' className={styles.table}>
            <thead>
              <tr>
                {COLS.map(col => (
                  <th key={`user_list_${col}`}>{t(`AccountUsers:Table:Header:${col}`)}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody className={styles.Users__list}>
              {findAccountUsersState.result.records.map(record => (
                <AccountsUserRow
                  key={`user-item-${record.id}`}
                  onEdit={onUserEdit}
                  onDelete={onDeleteUser}
                  user={record}
                />
              ))}
            </tbody>
          </Table>
          <div className={styles.AccountUsers__paginationContainer}>
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              totalCount={paginationInfo.totalCount}
              changePage={changePage}
              changePageSize={changePageSize}
            />
          </div>
        </>
      ) : !findAccountUsersState.loading ? (
        <NoAccounts />
      ) : null}
      {editUserModalNode}
      {deleteUserModalNode}
    </div>
  );
};

AccountUsers.displayName = 'AccountUsers';

export default React.memo(AccountUsers);
