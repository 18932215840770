import { Modal as KitModal } from '@just-ai/just-ui';
import React from 'react';

import styles from './styles.module.scss';

type ModalProps = React.ComponentProps<typeof KitModal>;

export const Modal = ({ children, ...restProps }: ModalProps) => {
  return (
    <KitModal {...restProps} className={styles.Modal}>
      {children}
    </KitModal>
  );
};
