export const acceptInvitationLocalization = {
  'AcceptInvitation:BE-error:accountsadmin.user.email.invalid': {
    eng: 'No user with this login was found. Try inviting them by email instead.',
    ru: 'Пользователь с таким логином не найден. Попробуйте пригласить его по email.',
  },
  'AcceptInvitation:BE-error:accountsadmin.account.too.many.users.added': {
    eng: 'This account has reached the limit on the number of invited members. New users cannot join it.',
    ru: 'Достигнуто максимальное количество приглашенных членов аккаунта. Новые пользователи не cмогут к нему присоединиться.',
  },
  'AcceptInvitation:BE-error:accountsadmin.account.invitation.internal.user.error': {
    eng: 'This user cannot be invited to join the account.',
    ru: 'Этого пользователя нельзя пригласить в аккаунт.',
  },
  'AcceptInvitation:BE-error:accountsadmin.account.user.already.invited': {
    eng: 'This user is already invited to the account.',
    ru: 'Этот пользователь уже приглашен в аккаунт.',
  },
  'AcceptInvitation:BE-error:accountsadmin.account.invitation.not.found.by.user': {
    eng: 'Failed to join the account. The invitation to the account was revoked.',
    ru: 'Не удалось присоединиться к аккаунту. Приглашение в аккаунт было отозвано.',
  },
  'AcceptInvitation:BE-error:accountsadmin.account.invitation.not.found.by.token': {
    eng: 'Failed to join the account. The invitation link is invalid or has expired.',
    ru: 'Не удалось присоединиться к аккаунту. Ссылка на приглашение некорректна или ее срок действия истек.',
  },
};
