import { create } from 'zustand';
import {
  AccountInvitationDto,
  AccountsBulkEditAllRequestDto,
  AccountsBulkEditSelectedRequestDto,
  AccountSearchFilter,
  AccountSearchRecord,
  AllowedAccountListItem,
  RoleGroupDto,
} from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import AccountsAndUsersService from '@just-ai/api/dist/services/AccountsAndUsersService';
import { AppLogger } from '@just-ai/logger';

type Paging = {
  pageNum: number;
  pageSize: number;
  totalCount: number;
};

export type AdminAccountsType = {
  paging?: Paging;
  records: AccountSearchRecord[];
};

export interface AccountsState {
  adminAccounts: AdminAccountsType;
  accounts: AllowedAccountListItem[];
  accountsLoading: boolean;
  invitations: AccountInvitationDto[];
  groupsAndRoles: RoleGroupDto[];
  getInvitationsByUser(userId: number): void;
  getAllowedAccounts(userId: number): void;
  findAccountsByFilterAsSadmin(filterData: AccountSearchFilter): Promise<void>;
  accountsBulkFeaturesEditSelected(
    accountsBulkEditSelectedRequestDto: AccountsBulkEditSelectedRequestDto
  ): Promise<void>;
  accountsBulkFeaturesEditAll(accountsBulkEditAllRequestDto: AccountsBulkEditAllRequestDto): Promise<void>;
  getGroupAndRoles(accountId: number): void;
}

const accountsadminService = new AccountsadminService();
const accountsAndUsersService = new AccountsAndUsersService();

export const useAccountsStore = create<AccountsState>(set => ({
  adminAccounts: {
    records: [],
  },
  accounts: [],
  accountsLoading: false,
  invitations: [],
  groupsAndRoles: [],
  async getInvitationsByUser(userId: number) {
    const response = await accountsadminService
      .getInvitationsByUser(userId)
      .catch(AppLogger.createNetworkHandler('getInvitationsByUser'));

    set({ invitations: response || [] });
  },
  async getAllowedAccounts(userId: number) {
    const response = await accountsadminService
      .getAllowedAccounts(userId)
      .catch(AppLogger.createNetworkHandler('getAllowedAccounts'));
    set({ accounts: response || [] });
  },
  async findAccountsByFilterAsSadmin(filterData: AccountSearchFilter) {
    set({ accountsLoading: true });
    return accountsadminService
      .findAccountsByFilterAsSadmin(filterData)
      .then(response => {
        set({
          adminAccounts: response,
          accountsLoading: false,
        });
      })
      .catch(AppLogger.createNetworkHandler('findAccountsByFilterAsSadmin'))
      .finally(() => {
        set({ accountsLoading: false });
      });
  },
  async accountsBulkFeaturesEditSelected(accountsBulkEditSelectedRequestDto: AccountsBulkEditSelectedRequestDto) {
    return accountsAndUsersService.bulkEditSelectedAccountsAsSadmin(accountsBulkEditSelectedRequestDto);
  },
  async accountsBulkFeaturesEditAll(accountsBulkEditAllRequestDto: AccountsBulkEditAllRequestDto) {
    return accountsAndUsersService.bulkEditAllAccountsAsSadmin(accountsBulkEditAllRequestDto);
  },
  async getGroupAndRoles(accountId: number) {
    const response = await accountsAndUsersService
      .getRoleGroups(accountId)
      .catch(AppLogger.createNetworkHandler('getGroupAndRoles'));

    set({ groupsAndRoles: response || [] });
  },
}));
