import { RefObject, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

export const ScrollShadowDiv = ({ children }: { children: React.ReactNode }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const onScrollHandler = (event: React.WheelEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setClientHeight(event.currentTarget.clientHeight);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const resetRefSizes = (ref: RefObject<HTMLDivElement>) => {
      if (!ref.current) return;

      setScrollTop(ref.current.scrollTop);
      setScrollHeight(ref.current.scrollHeight);
      setClientHeight(ref.current.clientHeight);
    };

    resetRefSizes(wrapperRef);
  }, [wrapperRef?.current?.clientHeight]);

  const getVisibleSides = (): { top: boolean; bottom: boolean } => {
    const isBottom = clientHeight === scrollHeight - Math.ceil(scrollTop);
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - Math.ceil(scrollTop);
    return {
      top: (isBottom || isBetween) && !(isTop && isBottom),
      bottom: (isTop || isBetween) && !(isTop && isBottom),
    };
  };
  return (
    <>
      <div ref={wrapperRef} className={styles.ScrollShadowDiv__wrapper} onScroll={onScrollHandler}>
        <div
          className={cn(
            styles.ScrollShadowDiv__top,
            getVisibleSides().top ? styles.ScrollShadowDiv__opacity : styles.ScrollShadowDiv__noOpacity
          )}
        />
        {children}
        <div
          className={cn(
            styles.ScrollShadowDiv__bottom,
            getVisibleSides().bottom ? styles.ScrollShadowDiv__opacity : styles.ScrollShadowDiv__noOpacity
          )}
        />
      </div>
    </>
  );
};
