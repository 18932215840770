import React from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

interface InnerPageProps {
  title: React.ReactNode;
  children: React.ReactNode;
  headerRightSlot?: React.ReactNode;
  cardsPage?: boolean;
  className?: string;
}
const InnerPage = ({ title, headerRightSlot, children, cardsPage, className }: InnerPageProps) => {
  return (
    <div className={cn(styles.InnerPage, className, { [styles.InnerPage__fixed]: cardsPage })}>
      <div className={styles.InnerPage__header}>
        <h2>{title}</h2>
        <div className={styles.InnerPage__rightSlot}>{headerRightSlot}</div>
      </div>
      <div className={styles.InnerPage__content}>{children}</div>
    </div>
  );
};

InnerPage.displayName = 'InnerPage';

export default InnerPage;
