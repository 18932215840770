import { SendSmsCodeDto, SmsCodeEndpointApi, VerifySmsCodeDto } from '@just-ai/api/dist/generated/Accountsadmin';

import { axios } from '../pipes/functions';

export default class PhoneVerificationService {
  static readonly BASE_PATH = '';
  private PhoneVerificationApi: SmsCodeEndpointApi;

  constructor() {
    this.PhoneVerificationApi = new SmsCodeEndpointApi({}, PhoneVerificationService.BASE_PATH, axios);
  }

  sendSmsCodeForPhoneVerification = async (sendSmsCodeDto: SendSmsCodeDto, gRecaptchaResponse: any) => {
    try {
      await this.PhoneVerificationApi.sendSmsCodeForPhoneVerification(sendSmsCodeDto, {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };

  verifySmsCode = async (verifySmsCodeDto: VerifySmsCodeDto) => {
    try {
      return await this.PhoneVerificationApi.verifySmsCode(verifySmsCodeDto);
    } catch (error) {
      throw error;
    }
  };

  sendSmsCodeForAuthorization = async (sendSmsCodeDto: SendSmsCodeDto, gRecaptchaResponse: any) => {
    try {
      await this.PhoneVerificationApi.sendSmsCodeForAuthorization(sendSmsCodeDto, {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };
}
