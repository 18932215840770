import React from 'react';

const BasePageBackground = ({ color }: { color: string }) => {
  return (
    <svg
      className='base-page_bg'
      width='1368'
      height='1080'
      viewBox='0 0 1368 1080'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f)'>
        <path
          d='M1168 590.786C1168 866.867 540.518 1213 267.912 1213C-4.69409 1213 631.432 757.479 631.432 481.399C631.432 205.318 461.029 -102 733.635 -102C1006.24 -102 1168 314.706 1168 590.786Z'
          fill={color}
          fillOpacity='0.6'
        />
      </g>
      <defs>
        <filter
          id='filter0_f'
          x='0'
          y='-302'
          width='1368'
          height='1715'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feGaussianBlur stdDeviation='100' result='effect1_foregroundBlur' />
        </filter>
      </defs>
    </svg>
  );
};

export default BasePageBackground;
