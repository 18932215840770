import { create } from 'zustand';
import { OptionsResponseDto } from '@just-ai/api/dist/generated/Accountsadmin';

export type ConfigState = {
  options?: OptionsResponseDto;
  setOptions(dto: OptionsResponseDto): void;
};

export const useConfigStore = create<ConfigState>(set => ({
  config: undefined,
  options: undefined,
  setOptions(dto) {
    return set({ options: dto });
  },
}));
