import React from 'react';
import { Total, UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { usePromiseProcessing } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { Modal } from 'components/Modal';

const accountsadminService = new AccountsadminService(axios);

type DeleteUserModalProps = {
  user: UserSearchRecord | undefined;
  account: Total;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};
const DeleteUserModal = ({ onSubmit, onCancel, isOpen, user, account }: DeleteUserModalProps) => {
  const [deleteInnerState, callDeleteInner] = usePromiseProcessing(
    async () => {
      if (!account.id || !user?.id) return;
      await accountsadminService.deleteUserFromAccount(account.id, user?.id);
      onSubmit();
    },
    { deps: [account.id, user?.id] }
  );

  return (
    <Modal
      isOpen={isOpen}
      inProgress={deleteInnerState.loading}
      title={t('DeleteUserModal:Title')}
      buttonSubmitText={t('DeleteUserModal:Submit')}
      buttonCancelText={t('Cancel')}
      onCancelClick={onCancel}
      onActionClick={callDeleteInner}
      buttonSubmitColor='danger'
      data-test-id='DeleteUserModal'
      titleTestId='DeleteUserModal.header'
      buttonSubmitTestId='DeleteUserModal.submitButton'
      buttonCancelTestId='DeleteUserModal.rejectButton'
    >
      <span dangerouslySetInnerHTML={{ __html: t('DeleteUserModal:Description', { email: user?.email }) }}></span>
    </Modal>
  );
};

export default React.memo(DeleteUserModal);
