import React from 'react';
import { history } from 'App';
import { Button } from '@just-ai/just-ui';

import { t } from 'localization';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import { BasePage } from 'views/BasePage';

class AccountOrUserUnsupported extends BasePage<any, any> {
  static contextType = AppContext;

  LoginService = new LoginService();

  state = {
    fetching: false,
    errors: [],
    loaded: true,
  };

  async componentDidMount() {
    const { setCurrentUser } = this.context;
    this.setState({ fetching: true });

    try {
      const { data } = await this.LoginService.checkIsUserAuthorized();
      await setCurrentUser(data);
      this.setState({
        fetching: false,
      });
    } catch (e) {
      history.push(`/c/login`);
    }
  }

  renderHead = () => {
    return (
      <div className='base-page_formarea-head flex-column margin-bottom-12'>
        <h2 className='margin-bottom-12'>{t('AccountOrUserUnsupported: form header text')}</h2>
        <p className='mb-0'>{t('AccountOrUserUnsupported: form body text')}</p>
      </div>
    );
  };

  renderButtons = () => {
    const {
      appConfig: { domains },
    } = this.context;
    const cloud = domains?.cc;
    return (
      cloud && (
        <div className='base-page_formarea-buttons'>
          <Button
            color='primary'
            outline
            onClick={() => {
              window.location.href = `${window.location.origin}/c/select-account`;
            }}
          >
            {t('AccountOrUserUnsupported:ChangeAccount')}
          </Button>
          <Button
            color='primary'
            onClick={() => {
              window.location.href = `${window.location.protocol}//${cloud.domain}`;
            }}
          >
            {t('AccountOrUserUnsupported: submit button text')}
          </Button>
        </div>
      )
    );
  };

  renderCaptcha = () => {};
}

export default AccountOrUserUnsupported;
