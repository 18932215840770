import { AuthorizationEndpointApi, AutoLoginDto, LoginDto, UserMode } from '@just-ai/api/dist/generated/Accountsadmin';
import { AppLogger } from '@just-ai/logger';

import { axios } from 'pipes/functions';

export default class LoginService {
  static readonly BASE_PATH = '';
  private LoginApi: AuthorizationEndpointApi;

  constructor() {
    this.LoginApi = new AuthorizationEndpointApi({}, LoginService.BASE_PATH, axios);
  }

  login = async (loginObject: LoginDto) => {
    try {
      return await this.LoginApi.login(loginObject);
    } catch (error) {
      throw error;
    }
  };

  checkIsUserAuthorized = async () => {
    try {
      return await this.LoginApi.checkIsUserAuthorized();
    } catch (error) {
      throw error;
    }
  };

  logout = async () => {
    try {
      return await this.LoginApi.logout1({});
    } catch (e) {}
  };

  autoLogin = async (token: AutoLoginDto['token']) => {
    try {
      await this.LoginApi.autoLogin({ token });
    } catch (error) {
      throw error;
    }
  };

  changeModeAndRedirect = async (
    userMode: UserMode,
    forDomain?: string,
    redirectUrl?: string
  ): Promise<string | null> => {
    const response = await this.LoginApi.changeModeAndRedirect(userMode, forDomain, redirectUrl).catch(
      AppLogger.createErrorHandler('changeModeAndRedirect')
    );
    if (!response) return null;
    return response.data;
  };
}
