import React, { FC } from 'react';

import Sidebar from 'components/Sidebar';

import styles from './styles.module.scss';

const WithSidebar: FC = ({ children }) => {
  return (
    <div className={styles.WithSidebar}>
      <Sidebar />
      <div className={styles.WithSidebar__content}>{children}</div>
    </div>
  );
};

export default WithSidebar;
