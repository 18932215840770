import { useCallback, useRef } from 'react';
import cn from 'classnames';
import { AllowedAccountListItem } from '@just-ai/api/dist/generated/Accountsadmin';
import { Icon } from '@just-ai/just-ui';

import styles from './styles.module.scss';

//TODO: return when decided how to display roles

// const hiddenItemsBlockWidth = 30;

export const AccountSelectItem = ({
  accountData,
  onlyOwnerAccounts,
  handleClick,
}: {
  accountData: AllowedAccountListItem;
  handleClick?: (id: number) => void;
  onlyOwnerAccounts?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  // const [hiddenItemsCount, setHiddenItemsCount] = useState(0);

  const isDisabled = (onlyOwnerAccounts && !accountData.owner) || !accountData.availableToSelect;

  // useLayoutEffect(() => {
  //   if (!wrapperRef.current) return;
  //   const wrapper = wrapperRef.current;
  //   const wrapperWidth = wrapper.offsetWidth;

  //   let currentWidth = 0;
  //   let hiddenItemsCount = 0;

  //   const rolesBlocks = wrapper.querySelectorAll('#SelectAccountRole') as unknown as HTMLSpanElement[];

  //   rolesBlocks.forEach((item, index) => {
  //     const itemWidth = item.offsetWidth + 4;
  //     if (currentWidth + itemWidth + (rolesBlocks.length - 1 === index ? 0 : hiddenItemsBlockWidth) <= wrapperWidth) {
  //       currentWidth += itemWidth;
  //     } else {
  //       currentWidth += itemWidth;
  //       hiddenItemsCount++;
  //     }
  //   });

  //   setHiddenItemsCount(hiddenItemsCount);
  // }, []);

  const handleClickWrapper = useCallback(() => {
    if (!accountData.id || !handleClick || isDisabled) return;
    handleClick(accountData.id);
  }, [accountData.id, handleClick, isDisabled]);

  return (
    <div
      className={cn(styles.SelectAccount__item, { [styles.disabled]: isDisabled })}
      onClick={handleClickWrapper}
      data-test-id={`Account.${accountData.name}`}
    >
      <div className={styles.SelectAccount__data}>
        <p className={cn('mb-0 text-ellipsis', styles.SelectAccount__name, { [styles.disabled]: isDisabled })}>
          {accountData.name}
        </p>
        {/* <div className={cn('flex-row gap-4', styles.SelectAccount__roles)} ref={wrapperRef}>
          {accountData.roles?.slice(0, accountData.roles?.length - hiddenItemsCount).map(role => (
            <small
              key={role}
              id='SelectAccountRole'
              className={cn(styles.SelectAccount__role, { [styles.disabled]: isDisabled })}
            >
              {role}
            </small>
          ))}
          {hiddenItemsCount > 0 && (
            <small
              className={cn(styles.SelectAccount__role, { [styles.disabled]: isDisabled })}
            >{`+${hiddenItemsCount}`}</small>
          )}
        </div> */}
      </div>
      {!isDisabled && <Icon name='farChevronRight' />}
    </div>
  );
};
