import {
  AllowedUserModesDto,
  ChangeEmailDto,
  ChangeFullNameDto,
  ChangeTimeZoneDto,
  ChangeUserPasswordDto,
  UserEndpointApi,
} from '@just-ai/api/dist/generated/Accountsadmin';
import { AppLogger } from '@just-ai/logger';

import { axios } from 'pipes/functions';

export default class UserService {
  static readonly BASE_PATH = '';
  private UserApi: UserEndpointApi;

  constructor() {
    this.UserApi = new UserEndpointApi({}, UserService.BASE_PATH, axios);
  }

  changeFullName = async (userId: number, fullNameDto: ChangeFullNameDto) => {
    try {
      return await this.UserApi.changeFullName(userId, fullNameDto);
    } catch (error) {
      throw error;
    }
  };

  uploadAndSetAvatar = async (userId: number, file: any) => {
    try {
      return await this.UserApi.uploadAndSetAvatar(userId, file);
    } catch (error) {
      throw error;
    }
  };

  changeLanguage = async (userId: number, language: string) => {
    try {
      return await this.UserApi.changeLanguage(userId, language);
    } catch (error) {
      throw error;
    }
  };

  changeEmail = async (userId: number, changeEmailDto: ChangeEmailDto, gRecaptchaResponse: any) => {
    try {
      return await this.UserApi.changeEmail(userId, changeEmailDto, {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };

  changePassword = async (userId: number, changePasswordDto: ChangeUserPasswordDto, gRecaptchaResponse: any) => {
    try {
      return await this.UserApi.changePassword(userId, changePasswordDto, {
        headers: { 'g-recaptcha-response': gRecaptchaResponse },
      });
    } catch (error) {
      throw error;
    }
  };

  deleteAvatar = async (userId: number) => {
    try {
      return await this.UserApi.deleteAvatar(userId);
    } catch (error) {
      throw error;
    }
  };

  checkIsCaptchaNeeded = async (userId: number, actionType: string) => {
    try {
      return await this.UserApi.isCaptchaNeeded(userId, actionType);
    } catch (error) {
      throw error;
    }
  };

  changeTimezone = async (userId: number, timezone: ChangeTimeZoneDto) => {
    return await this.UserApi.changeTimeZone(userId, timezone);
  };

  getAllowedModes = async (userId: number): Promise<AllowedUserModesDto | null> => {
    const response = await this.UserApi.getAllowedModes(userId).catch(AppLogger.createErrorHandler('getAllowedModes'));
    if (!response) return null;
    return response.data;
  };
}
