import React from 'react';
import { Table } from 'reactstrap';
import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';

import { t } from 'localization';

import AccountRow from '../AddUsersRow';

import styles from './styles.module.scss';

const COLS = [{ code: 'Id' }, { code: 'Login' }, { code: 'Name' }, { code: 'Owner' }];

type AddUsersTable = {
  users: UserSearchRecord[];
  checkedUsers: Map<number, UserSearchRecord>;
  handleUserCheckboxChange: (accountId: number) => void;
};
function AddUsersTable(props: AddUsersTable) {
  return (
    <Table data-test-id='addUsersTable.Table' className={styles.AddUsersTable}>
      <thead>
        <tr>
          <th></th>
          {COLS.map(col => (
            <th key={`addUsersTable_list_${col.code}`}>{t(`AddUsersPage:Table:Header:${col.code}`)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.users.map(user => (
          <AccountRow
            key={user.id}
            user={user}
            checked={props.checkedUsers.has(user.id)}
            checkboxChange={props.handleUserCheckboxChange}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default React.memo(AddUsersTable);
