import React from 'react';
import { history } from 'App';
import {
  AccountInvitationDto,
  AllowedAccountListItem,
  AllowedUserModesDto,
  DomainOptions,
  UserMode,
} from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import AccountAndUsersService from '@just-ai/api/dist/services/AccountsAndUsersService';

import localize, { t, tWithPlural } from 'localization';
import { getCurrentDomainObject, getDomainData, isAxiosError, isDev } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import { ScrollShadowDiv } from 'components/ScrollShadowDiv';
import { isInternal } from 'helpers/isAccessFunction';
import LoginService from 'service/LoginService';
import ProjectGroupsService from 'service/ProjectGroupService';
import { ssoService } from 'service/SsoService';
import UserService from 'service/UserService';
import { BasePage, BasePropTypes, Error } from 'views/BasePage';

import { projectGroupSelectLocalization } from './localization/projectGroupSelect.loc';
import { AccountSelectInfoBlock } from './AccountSelectInfoBlock';
import { AccountSelectItem } from './AccountSelectItem';

import styles from './styles.module.scss';

localize.addTranslations(projectGroupSelectLocalization);

class State {
  projectGroupList: AllowedAccountListItem[] = [];
  projectGroupInvitationsList: AccountInvitationDto[] = [];
  fetching: boolean = false;
  errors: Error[] | [] = [];
  loaded: boolean = false;
  allowedModes: AllowedUserModesDto | null = null;
}

class ProjectGroupSelect extends BasePage<BasePropTypes, State> {
  static contextType = AppContext;
  state = new State();

  ProjectGroupsService = new ProjectGroupsService();
  LoginService = new LoginService();
  userService = new UserService();
  accountsadminService = new AccountsadminService();
  accountAndUsersService = new AccountAndUsersService();

  componentDidMount() {
    const load = async () => {
      this.setState({ fetching: true });
      const { setCurrentUser, appConfig } = this.context;
      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        setCurrentUser(data);
        const productDomainData = getCurrentDomainObject(appConfig.domains);
        const projectGroupsData = await Promise.all([
          this.accountsadminService.getAllowedAccounts(data.userData.userId, productDomainData?.product),
          this.ProjectGroupsService.getInvitationsByUser(data.userData.userId),
          this.userService.getAllowedModes(data.userData.userId),
        ]);
        this.setState({
          projectGroupList: projectGroupsData[0],
          projectGroupInvitationsList: projectGroupsData[1],
          allowedModes: projectGroupsData[2],
        });
      } catch {
        history.push('/c/login');
      }
      this.setState({ fetching: false, loaded: true });
    };

    load();
  }

  acceptInvitation = async (accountId: number) => {
    const { currentUser } = this.context;
    await this.ProjectGroupsService.acceptInvitationByUserId(currentUser.userData.userId, accountId);
    this.selectProjectGroup(accountId);
  };

  selectProjectGroup = async (id: number) => {
    const {
      location: { search },
    } = this.props;
    const { appConfig } = this.context;

    let { redirectUrl } = getDomainData(search, appConfig?.domains);
    try {
      const productName = Object.values(appConfig.domains as { [key: string]: DomainOptions }).find(
        (domainData: DomainOptions) => domainData.domain === window.location.hostname
      )?.product;

      if (!productName) return;

      await this.accountsadminService.changeSelectedAccount({ accountId: id, product: productName });
      window.location.href = isDev() ? '/' : (redirectUrl as string);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.setState({ errors });
      }
    }
  };

  getCcAccountLink = () => {
    const { appConfig } = this.context;

    return `${appConfig.security?.ccBaseUrl}/c/accounts`;
  };

  changeMode = async () => {
    const { INTERNAL, REGULAR } = UserMode;
    try {
      ssoService.changeUserMode(isInternal() ? REGULAR : INTERNAL, window.location.href);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.setState({
          errors: errors,
        });
      }
    }
  };

  renderHead = () => {
    return (
      <div className='base-page_formarea-head flex-column gap-8'>
        <h1>{t('ProjectGroupSelect:Header:Title')}</h1>
        <p className='text-center mb-0'>{this.context.currentUser?.userData?.email}</p>
      </div>
    );
  };

  renderBody = () => {
    const { projectGroupList, allowedModes } = this.state;
    const { currentUser, appConfig } = this.context;

    const productDomainData = getCurrentDomainObject(appConfig.domains);
    const currentAccountIndex = projectGroupList?.findIndex(
      account =>
        account.id === currentUser.userData.accountId &&
        (!productDomainData?.ownerOnly || (productDomainData.ownerOnly && account.owner))
    );
    const currentAccount = currentAccountIndex !== -1 ? projectGroupList[currentAccountIndex] : null;

    const availableAccounts =
      currentAccountIndex !== -1
        ? projectGroupList.slice(0, currentAccountIndex).concat(projectGroupList.slice(currentAccountIndex + 1))
        : projectGroupList;

    const numberOfDisabledAccounts = availableAccounts.map(
      account => productDomainData?.ownerOnly && !account.owner
    ).length;

    const availableToChangeAccounts = projectGroupList.filter(account => account.availableToSelect);
    return (
      <ScrollShadowDiv>
        <div className={styles.SelectAccount__body}>
          {isInternal() && !productDomainData?.internalUserUiAvailable && (
            <AccountSelectInfoBlock
              blockType='MANAGER'
              modifier='INTERNAL'
              allowedModes={allowedModes}
              changeMode={this.changeMode}
              product={productDomainData}
              shouldSubtextShown
              textCenter
            />
          )}
          {!isInternal() && !availableToChangeAccounts.length && (
            <AccountSelectInfoBlock
              blockType='NO_ROLES_FOR_ACCOUNT'
              modifier={
                appConfig.security?.usersCanCreateAccount ? 'ACCOUNT_CREATION_ALLOWED' : 'ACCOUNT_CREATION_NOT_ALLOWED'
              }
              product={productDomainData}
              shouldSubtextShown
            />
          )}
          {!currentAccount && availableToChangeAccounts.length > 0 && productDomainData && !isInternal() && (
            <AccountSelectInfoBlock
              blockType='NO_SUITABLE_ACCOUNTS'
              modifier={
                appConfig.security?.usersCanCreateAccount ? 'ACCOUNT_CREATION_ALLOWED' : 'ACCOUNT_CREATION_NOT_ALLOWED'
              }
              product={productDomainData}
              shouldSubtextShown={!!numberOfDisabledAccounts}
              textCenter
            />
          )}
          {currentAccount && (
            <div className='flex-column gap-12'>
              <p className='font-bold font-size-12 mb-0'>{t('SelectAccount:BlockTitle:CurrentAccount')}</p>
              <AccountSelectItem
                accountData={currentAccount}
                onlyOwnerAccounts={productDomainData?.ownerOnly}
                handleClick={this.selectProjectGroup}
              />
            </div>
          )}
          {!projectGroupList.length && !isInternal() && (
            <AccountSelectInfoBlock
              blockType='NO_ACCOUNTS'
              modifier={
                appConfig.security?.usersCanCreateAccount ? 'ACCOUNT_CREATION_ALLOWED' : 'ACCOUNT_CREATION_NOT_ALLOWED'
              }
              product={productDomainData}
              textCenter
            />
          )}

          {availableAccounts.length > 0 && !isInternal() && (
            <div className='flex-column gap-12'>
              <div>
                <p className='font-bold font-size-12 mb-0'>{t('SelectAccount:BlockTitle:AvailableAccounts')}</p>
                {productDomainData?.ownerOnly && (
                  <p className='font-size-12 mb-0'>
                    {t('SelectAccount:BlockTitle:AvailableAccounts:subtitle', {
                      productName: productDomainData.product,
                    })}
                  </p>
                )}
              </div>
              {availableAccounts.map(account => (
                <AccountSelectItem
                  accountData={account}
                  key={account.id}
                  onlyOwnerAccounts={productDomainData?.ownerOnly}
                  handleClick={this.selectProjectGroup}
                />
              ))}
            </div>
          )}
        </div>
      </ScrollShadowDiv>
    );
  };

  renderFooter = () => {
    const { projectGroupInvitationsList } = this.state;

    return (
      <div className={styles.SelectAccount__footer}>
        <a href={this.getCcAccountLink()} className='font-size-12' data-test-id='SelectAccount.GoToAccounts'>
          {t('SelectAccount:Footer:Link')}
        </a>
        {projectGroupInvitationsList.length > 0 && (
          <small className={styles.SelectAccount__invites} data-test-id='SelectAccount.Invites'>
            {tWithPlural('SelectAccount:Footer:Invites', projectGroupInvitationsList.length)}
          </small>
        )}
      </div>
    );
  };
}

export default ProjectGroupSelect;
