import Cookie from 'universal-cookie';

const cookieName = 'UTM_MARKS';
const cookieMaxAge = 3600;

class UtmKeeper {
  private cookie = new Cookie();

  setUtmMarks = () => {
    const searchParams = new URLSearchParams(window.location.search);

    const utmObject = Array.from(searchParams.keys()).reduce<{ [key: string]: string }>((utmObject, key) => {
      const valueOfKey = searchParams.get(key);

      if (key.startsWith('utm_') && valueOfKey) {
        utmObject[key] = valueOfKey;
      }

      return utmObject;
    }, {});

    if (Object.keys(utmObject).length) {
      this.cookie.set(cookieName, utmObject, {
        maxAge: cookieMaxAge,
        path: '/',
      });
    }
  };

  getUtmMarks = (): { [key: string]: string } | undefined => {
    const cookieString = this.cookie.get(cookieName, {
      doNotParse: true,
    });

    if (!cookieString) return;
    try {
      return JSON.parse(cookieString);
    } catch (error) {
      console.error(error);
    }
  };

  clearUtmMarks = () => {
    this.cookie.remove(cookieName, { path: '/' });
  };
}

export const utmKeeperService = new UtmKeeper();
