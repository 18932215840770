import { create } from 'zustand';
import { AppLogger } from '@just-ai/logger';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { ProjectFeatureDto, SystemFeatureDto } from '@just-ai/api/dist/generated/Accountsadmin';

const accountsadminService = new AccountsadminService();

export interface FeaturesState {
  systemFeatures: SystemFeatureDto[];
  projectFeatures: ProjectFeatureDto[];
  defaultFeatures: ProjectFeatureDto[];
  featuresLoading: boolean;
  loadFeatures(): void;
  loadDefaultFeatures(): void;
}
export const useFeaturesStore = create<FeaturesState>(set => ({
  projectFeatures: [],
  defaultFeatures: [],
  systemFeatures: [],
  featuresLoading: false,
  async loadFeatures() {
    set({ featuresLoading: true });
    accountsadminService
      .getAllProjectFeatures()
      .then(response => {
        set({
          projectFeatures: response,
          defaultFeatures: response.filter(el => el.defaultFeature),
        });
      })
      .catch(AppLogger.createNetworkHandler('loadFeatures'))
      .finally(() => {
        set({ featuresLoading: false });
      });
  },
  async loadDefaultFeatures() {
    set({ featuresLoading: true });
    accountsadminService
      .getAllSystemFeatures()
      .then(response => {
        set({ systemFeatures: response });
      })
      .catch(AppLogger.createNetworkHandler('loadDefaultFeatures'))
      .finally(() => set({ featuresLoading: false }));
  },
}));
