import {
  AccountInvitationEndpointApi,
  AccountsEndpointV2Api,
  UserEndpointApi,
} from '@just-ai/api/dist/generated/Accountsadmin';

import { axios } from 'pipes/functions';

export default class ProjectGroupsService {
  static readonly BASE_PATH = '';
  private UserEndpointApi: UserEndpointApi;
  private AccountsEndpointApi: AccountsEndpointV2Api;
  private AccountInvitationEndpointApi: AccountInvitationEndpointApi;

  constructor() {
    this.UserEndpointApi = new UserEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
    this.AccountsEndpointApi = new AccountsEndpointV2Api({}, ProjectGroupsService.BASE_PATH, axios);
    this.AccountInvitationEndpointApi = new AccountInvitationEndpointApi({}, ProjectGroupsService.BASE_PATH, axios);
  }

  getAllowedAccounts = async (userId: number) => {
    return (await this.UserEndpointApi.getAllowedAccounts(userId)).data;
  };

  createProjectGroup = async (fullName: string, gRecaptchaResponse: any) => {
    return (
      await this.AccountsEndpointApi.createAccount(
        { fullName },
        {
          headers: { 'g-recaptcha-response': gRecaptchaResponse },
        }
      )
    ).data;
  };

  getInvitationsByUser = async (userId: number) => {
    return (await this.AccountInvitationEndpointApi.getInvitationsByUser(userId)).data;
  };

  acceptInvitationByUserId = async (userId: number, accountId: number) => {
    return (await this.AccountInvitationEndpointApi.acceptInvitationByUserId(userId, accountId)).data;
  };

  deleteInvitation = async (accountId: number, invitationId: number) => {
    return (await this.AccountInvitationEndpointApi.deleteInvitation(accountId, invitationId)).data;
  };

  checkIsCaptchaNeeded = async (userId: number, actionType: string) => {
    return await this.UserEndpointApi.isCaptchaNeeded(userId, actionType);
  };
}
