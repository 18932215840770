import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { history } from 'App';
import { getAllowedRoutes } from 'routes';
import { UserMode } from '@just-ai/api/dist/generated/Accountsadmin';
import { DropdownButton, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui/dist';
import { DropdownItem } from '@just-ai/just-ui/dist';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';

import { OptionsResponseDto } from 'api/cloud/client';
import { t } from 'localization';
import localize from 'localization';
import { appStores } from 'store';

import { useAppContext } from 'components/AppContext';
import logoUsd from 'images/tovie/t-cloudFull.svg';

import { headerLocalization } from './localization/header.loc';
import { logo } from './Logo';

import './index.scss';

localize.addTranslations(headerLocalization);
const { INTERNAL, REGULAR } = UserMode;

const Header = () => {
  // @ts-ignore
  const { appConfig, currentUser }: OptionsResponseDto | null = useAppContext();
  const { allowedModes, changeModeAndRedirect } = appStores.Users(store => store);

  const isInternal = currentUser.userData.internal;
  const canChangeMode = useMemo(() => {
    return (isInternal && allowedModes.includes(REGULAR)) || (!isInternal && allowedModes.includes(INTERNAL));
  }, [allowedModes, isInternal]);

  const { domains, euroInstance } = appConfig;

  const changeMode = useCallback(
    () =>
      changeModeAndRedirect(isInternal ? REGULAR : INTERNAL, domains.cc.domain, window.location.href).then(() =>
        window.location.reload()
      ),

    [changeModeAndRedirect, domains.cc.domain, isInternal]
  );

  return (
    <div className='header'>
      <div className='logo'>
        <Link to='/'>{euroInstance ? <img src={logoUsd} alt='cloud logo' /> : logo}</Link>
      </div>
      <div className='header-menu-wrap'>
        <DropdownButton direction='down'>
          <DropdownToggle>
            <div
              className='user-menu-button'
              data-test-id='cloud.header.userButton'
              style={
                currentUser.userData.avatarUrl
                  ? { backgroundImage: `url(${currentUser.userData.avatarUrl})` }
                  : undefined
              }
            >
              {!currentUser.userData.avatarUrl && <Icon name='falUser' color='primary' />}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {getAllowedRoutes().map(sidebarItem => (
              <DropdownItem key={`dropdown-${sidebarItem.title}`} onClick={() => history.push(sidebarItem.link)}>
                {t(`Header: ${sidebarItem.title}`)}
              </DropdownItem>
            ))}
            {canChangeMode && (
              <DropdownItem key='dropdown-change-mode' onClick={changeMode}>
                {isInternal ? t('UserMode:Regular') : t('UserMode:Internal')}
              </DropdownItem>
            )}
            <DropdownItem onClick={() => history.push('/c/logout')} data-test-id='cloud.header.user.logout'>
              <span style={{ color: 'var(--gray-600)' }}>{t('Header: logout')}</span>
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
        <CloudMenu
          cloudDomains={domains}
          countryIsoCode={currentUser?.userData?.countryIsoCode}
          translate={t}
          universalLoginMenuEnabled
          isEuroInstance={euroInstance}
        />
      </div>
    </div>
  );
};

export default Header;
