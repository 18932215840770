import { AccountsEndpointV2Api, ChangeCountryIsoCodeRequest } from '@just-ai/api/dist/generated/Accountsadmin';

import { axios } from '../pipes/functions';

export default class AccountsService {
  static readonly BASE_PATH = '';
  private AccountsEndpointApi: AccountsEndpointV2Api;

  constructor() {
    this.AccountsEndpointApi = new AccountsEndpointV2Api({}, AccountsService.BASE_PATH, axios);
  }

  changeCountryIsoCode = async (accountId: number, changeCountryIsoCode: ChangeCountryIsoCodeRequest) => {
    return this.AccountsEndpointApi.changeCountryIsoCode(accountId, changeCountryIsoCode);
  };
}
