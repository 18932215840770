import React from 'react';
import { AccountInvitationSearchDto } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { usePromiseProcessing } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { Modal } from 'components/Modal';

const accountsadminService = new AccountsadminService(axios);

type DeleteInviteModalProps = {
  accountInvitation: AccountInvitationSearchDto | undefined;
  isOpen: boolean;
  accountId: number | undefined;
  onSubmit: () => void;
  onCancel: () => void;
};
const DeleteInviteModal = ({ onSubmit, onCancel, isOpen, accountInvitation, accountId }: DeleteInviteModalProps) => {
  const [deleteInnerState, callDeleteInner] = usePromiseProcessing(
    async () => {
      if (
        (!accountInvitation?.invitationId && accountInvitation?.invitationId !== 0) ||
        (!accountId && accountId !== 0)
      )
        return;
      await accountsadminService.deleteInvitation(accountId, accountInvitation.invitationId);
      onSubmit();
    },
    { deps: [accountInvitation?.invitationId, accountId] }
  );

  return (
    <Modal
      isOpen={isOpen}
      inProgress={deleteInnerState.loading}
      title={t('DeleteInviteModal:Title')}
      buttonSubmitText={t('Delete')}
      buttonCancelText={t('Cancel')}
      onCancelClick={onCancel}
      onActionClick={callDeleteInner}
      buttonSubmitColor='danger'
      data-test-id='DeleteInviteModal'
      titleTestId='DeleteInviteModal.header'
      buttonSubmitTestId='DeleteInviteModal.submitButton'
      buttonCancelTestId='DeleteInviteModal.rejectButton'
    >
      <span
        dangerouslySetInnerHTML={{
          __html: t('DeleteInviteModal:Description', { email: accountInvitation?.email }),
        }}
      ></span>
    </Modal>
  );
};

export default React.memo(DeleteInviteModal);
