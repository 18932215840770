import queryString from 'query-string';
import { OptionsResponseDto, UserMode } from '@just-ai/api/dist/generated/Accountsadmin';

import { isDev } from 'pipes/functions';

class SsoService {
  private appOptions!: OptionsResponseDto;

  setOptions(options: OptionsResponseDto) {
    this.appOptions = options;
  }

  getSsoDomain() {
    return this.appOptions.domains[this.appOptions.sso.crossDomainLoginProduct].domain;
  }

  performSsoForDomain(redirectUrl: string, currentSearch?: string) {
    let additionalQueryParams = '';

    if (currentSearch) {
      const queryParams = queryString.parse(currentSearch.replace('?', ''));
      const loginPath = queryParams.loginPath
        ? `&loginPath=${encodeURIComponent(queryParams.loginPath as string)}`
        : '';
      const omitNeedAuth = queryParams.omitRedirectUrlWhenNeedAuth
        ? `&omitRedirectUrlWhenNeedAuth=${encodeURIComponent(queryParams.omitRedirectUrlWhenNeedAuth as string)}`
        : '';

      additionalQueryParams = loginPath + omitNeedAuth;
    }
    const forDomain = encodeURIComponent(window.location.hostname);
    const innerRedirectUrl = encodeURIComponent(
      isDev() ? `${window.location.protocol}//${window.location.host}/` : redirectUrl
    );

    window.location.href = `${
      window.location.protocol
    }//${this.getSsoDomain()}/api/accountsadmin/c/authorization/perform-sso?forDomain=${forDomain}${additionalQueryParams}&redirectUrl=${innerRedirectUrl}`;
  }

  toPerformSsoForSso = (redirectUrl: string) => {
    const forDomain = encodeURIComponent(this.getSsoDomain());
    const innerRedirectUrl = encodeURIComponent(redirectUrl);

    window.location.href = `${window.location.origin}/api/accountsadmin/c/authorization/perform-sso?forDomain=${forDomain}&redirectUrl=${innerRedirectUrl}`;
  };

  changeAccount = (forDomain: string, redirectUrl: string, accountId: number | null) => {
    const innerForDomain = encodeURIComponent(isDev() ? window.location.hostname : forDomain);
    const innerRedirectUrl = encodeURIComponent(
      isDev() ? `${window.location.protocol}//${window.location.host}/` : redirectUrl
    );

    window.location.href = `${window.location.protocol}//${
      this.appOptions.domains.cc.domain
    }/api/accountsadmin/c/authorization/change-account-and-redirect?forDomain=${innerForDomain}&redirectUrl=${innerRedirectUrl}&accountId=${
      accountId ? encodeURIComponent(accountId) : accountId
    }`;
  };

  changeUserMode = (userMode: UserMode, redirectUrl: string) => {
    const url = new URL(`${window.location.origin}/api/accountsadmin/c/authorization/change-user-mode-and-redirect`);
    url.searchParams.set('forDomain', this.getSsoDomain());
    url.searchParams.set('redirectUrl', redirectUrl);
    url.searchParams.set('userMode', userMode);

    window.location.href = url.toString();
  };

  isCurrentDomainSso() {
    return this.getSsoDomain() === window.location.host;
  }

  stayOrRedirect(redirectUrl: string) {
    if (this.isCurrentDomainSso()) {
      window.location.href = redirectUrl;
    } else {
      this.toPerformSsoForSso(redirectUrl);
    }
  }
}

export const ssoService = new SsoService();
