import React, { useCallback } from 'react';
import { DateTime } from 'luxon';
import { AccountInvitationSearchDto } from '@just-ai/api/dist/generated/Accountsadmin';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui';

import { t } from 'localization';
import { appStores } from 'store';

import { useAppContext } from 'components/AppContext';
import isAccess, { isInternal } from 'helpers/isAccessFunction';
import BadgeGroups from 'views/AccountDetail/components/BadgeGroups';

import styles from './styles.module.scss';

interface InvitationRowProps {
  invitation: AccountInvitationSearchDto;
  onDelete(accountInvitation: AccountInvitationSearchDto): void;
  onEdit(accountInvitation: AccountInvitationSearchDto): void;
}
const InvitationRow = ({ invitation, onDelete, onEdit }: InvitationRowProps) => {
  const { groupsAndRoles } = appStores.Accounts(state => ({ groupsAndRoles: state.groupsAndRoles }));
  const currentUserId = appStores.CurrentUser(state => state.user?.userId);
  const { appConfig, language } = useAppContext();

  const isHasAccessToInvitationEdit = isAccess('ROLES_WRITE') || isInternal();

  const invitationProduct =
    appConfig?.domains &&
    Object.values(appConfig?.domains).find(domain => domain.domain === invitation.domain && domain.invitationAvailable)
      ?.appTitle;

  const getExpirationString = useCallback(() => {
    const now = DateTime.now();
    const expirationTime = DateTime.fromMillis(invitation.expiresAt as unknown as number);
    if (now.toMillis() > expirationTime.toMillis()) return t('InvtitationRow:Expires:Expired');
    return t('InvtitationRow:Expires', { timeLeft: expirationTime.setLocale(language).toRelative() });
  }, [invitation.expiresAt, language]);

  return (
    <>
      <tr
        className={styles.InvitationRow}
        data-test-id={`InvitationRow.userListTable.userListItem.${invitation.email}`}
      >
        <td className={styles.InvitationRow__id}>
          <div data-test-id='InvitationRow.userListTable.ListItem.email'>{invitation.email}</div>
          <div className='font-color-secondary' data-test-id='InvitationRow.userListTable.ListItem.id'>
            ID: {invitation.userId}
          </div>
        </td>
        <td data-test-id='InvitationRow.userListTable.ListItem.invitation'>
          {invitationProduct && <p className='mb-0 font-bold'>{invitationProduct}</p>}
          <small>({invitation.domain})</small>
          <p className='mb-0'>{getExpirationString()}</p>
        </td>
        <td className={styles.InvitationRow__fullName}>
          <p>{invitation.userFullName}</p>
        </td>
        <td className={styles.InvitationRow__accounts}>
          <BadgeGroups selected={invitation.userRoles} groups={groupsAndRoles} />
        </td>
        <td className={styles.InvitationRow__button}>
          {isHasAccessToInvitationEdit && (
            <DropdownButton data-test-id={`AccountsPage.AccountCard.${invitation.email}.dropdown`} direction='down'>
              <DropdownToggle iconButton compact size='md'>
                <Icon name='farEllipsisH' color='secondary' size='md' />
              </DropdownToggle>
              <DropdownMenu right>
                {isHasAccessToInvitationEdit && (
                  <DropdownItem
                    onClick={() => onEdit(invitation)}
                    data-test-id={`InvitationRow.${invitation.email}.dropdown.Edit`}
                  >
                    {t('AccountsUserRow:Edit')}
                  </DropdownItem>
                )}
                {isHasAccessToInvitationEdit && invitation.userId !== currentUserId && (
                  <DropdownItem
                    danger
                    onClick={() => onDelete(invitation)}
                    data-test-id={`InvitationRow.${invitation.email}.dropdown.Delete`}
                  >
                    {t('InvtitationRow:Delete')}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </DropdownButton>
          )}
        </td>
      </tr>
    </>
  );
};

InvitationRow.displayName = 'InvitationRow';

export default React.memo(InvitationRow);
