export const invitePageLocalization = {
  'InvitePage:Title': {
    ru: 'Пригласить пользователей',
    eng: 'Invite users',
  },
  'InvitePage:ChooseDomain': {
    ru: 'На какой продукт дать ссылку в приглашении',
    eng: 'Which product to link to in the invitation',
  },
  'InvitePage:Submit': {
    ru: 'Продолжить',
    eng: 'Continue',
  },
  'InvitePage:Back': {
    ru: 'Назад',
    eng: 'Back',
  },
  'InvitePage:Info': {
    ru: 'В письмах пользователям будет ссылка на этот продукт',
    eng: 'Emails to users will contain a link to this product.',
  },
};
