import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import { AllowedUserModesDto, DomainOptions, UserMode } from '@just-ai/api/dist/generated/Accountsadmin';
import { Button } from '@just-ai/just-ui';

import localize, { t } from 'localization';

import { useAppContext } from 'components/AppContext';
import { isInternal } from 'helpers/isAccessFunction';

import styles from './styles.module.scss';

const ProductName: { [key: string]: string } = {
  aimyvoice: 'Aimyvoice',
  cc: 'Cloud',
  aimylogic: 'Aimylogic',
};

const BlockTypes = {
  NO_ACCOUNTS: 'NO_ACCOUNTS',
  NO_SUITABLE_ACCOUNTS: 'NO_SUITABLE_ACCOUNTS',
  MANAGER: 'MANAGER',
  NO_ROLES_FOR_ACCOUNT: 'NO_ROLES_FOR_ACCOUNT',
};

const BlockTypesModifier = {
  ACCOUNT_CREATION_ALLOWED: 'ACCOUNT_CREATION_ALLOWED',
  ACCOUNT_CREATION_NOT_ALLOWED: 'ACCOUNT_CREATION_NOT_ALLOWED',
  INTERNAL: 'INTERNAL',
};

const BlockTypesFooter = {
  [BlockTypesModifier.ACCOUNT_CREATION_ALLOWED]: (
    blockType: keyof typeof BlockTypes,
    modifier: keyof typeof BlockTypesModifier,
    buttonHandler: () => void = () => {}
  ) => (
    <Button color='primary' onClick={buttonHandler} data-test-id='SelectAccount.Error.CreateAccount'>
      {t(`SelectAccount:${BlockTypes[blockType]}:${BlockTypesModifier[modifier]}:ActionText`)}
    </Button>
  ),
  [BlockTypesModifier.ACCOUNT_CREATION_NOT_ALLOWED]: (
    blockType: keyof typeof BlockTypes,
    modifier: keyof typeof BlockTypesModifier
  ) => {
    if (!localize.checkExistKey(`SelectAccount:${BlockTypes[blockType]}:${BlockTypesModifier[modifier]}:ActionText`))
      return null;
    return (
      <p className='mb-0 text-center'>
        {t(`SelectAccount:${BlockTypes[blockType]}:${BlockTypesModifier[modifier]}:ActionText`)}
      </p>
    );
  },
  [BlockTypesModifier.INTERNAL]: (
    blockType: keyof typeof BlockTypes,
    modifier: keyof typeof BlockTypesModifier,
    buttonHandler: () => void = () => {},
    canChangeMode: boolean = false
  ) => {
    if (!canChangeMode) return null;
    return (
      <Button color='primary' onClick={buttonHandler} data-test-id='SelectAccount.Error.ChangeMode'>
        {t(`SelectAccount:${BlockTypes[blockType]}:${BlockTypesModifier[modifier]}:ActionText`)}
      </Button>
    );
  },
};

export const AccountSelectInfoBlock = ({
  blockType,
  modifier,
  allowedModes,
  changeMode,
  product,
  shouldSubtextShown,
  textCenter,
}: {
  blockType: keyof typeof BlockTypes;
  modifier: keyof typeof BlockTypesModifier;
  allowedModes?: AllowedUserModesDto | null;
  changeMode?: () => void;
  product?: DomainOptions;
  shouldSubtextShown?: boolean;
  textCenter?: boolean;
}) => {
  const { appConfig } = useAppContext();

  const capitalizedProductName = product?.appTitle
    ? product.appTitle
    : product?.product && ProductName[product?.product];

  const canChangeMode = useMemo(() => {
    const { INTERNAL, REGULAR } = UserMode;

    if (!allowedModes) return;
    return (
      (isInternal() && allowedModes.allowedModes.includes(REGULAR)) ||
      (!isInternal() && allowedModes.allowedModes.includes(INTERNAL))
    );
  }, [allowedModes]);

  const buttonHandler = useCallback(() => {
    if (modifier === BlockTypesModifier.ACCOUNT_CREATION_ALLOWED) {
      window.location.href = `${appConfig?.security.ccBaseUrl}/c/accounts`;
    }
    if (modifier === BlockTypesModifier.INTERNAL && changeMode) {
      changeMode();
    }
  }, [appConfig?.security.ccBaseUrl, changeMode, modifier]);

  return (
    <div className={styles.SelectAccount__placeholder} data-test-id='SelectAccount.Error'>
      <p className={cn('mb-0', { 'text-center': textCenter })}>
        {t(`SelectAccount:${BlockTypes[blockType]}:Text`, { product: capitalizedProductName })}
      </p>
      {shouldSubtextShown &&
        (blockType === BlockTypes.NO_ROLES_FOR_ACCOUNT ? (
          <ul className={styles.SelectAccount__list}>
            <li className='mb-0'>
              {t(`SelectAccount:${BlockTypes[blockType]}:SubText:First`, { product: capitalizedProductName })}
            </li>
            <li className='mb-0'>
              {t(`SelectAccount:${BlockTypes[blockType]}:SubText:Second`, { product: capitalizedProductName })}
            </li>

            {modifier === BlockTypesModifier.ACCOUNT_CREATION_ALLOWED && (
              <li className='mb-0'>
                {t(`SelectAccount:${BlockTypes[blockType]}:SubText:Third`, { product: capitalizedProductName })}
              </li>
            )}
          </ul>
        ) : (
          localize.checkExistKey(`SelectAccount:${BlockTypes[blockType]}:SubText`) && (
            <p
              className='mb-0 text-center'
              dangerouslySetInnerHTML={{ __html: t(`SelectAccount:${BlockTypes[blockType]}:SubText`) }}
            ></p>
          )
        ))}

      {BlockTypesFooter[modifier](blockType, modifier, buttonHandler, canChangeMode)}
    </div>
  );
};
