import React from 'react';
import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import { Checkbox } from '@just-ai/just-ui';

import styles from './styles.module.scss';

type AddUserTableRowType = {
  user: UserSearchRecord;
  checked: boolean;
  checkboxChange: (id: number) => void;
};
const AddUserTableRow = ({ user, checked, checkboxChange }: AddUserTableRowType) => {
  const accountString = user.accounts.map(account => account.name).join(', ');

  return (
    <tr className={styles.AddUsersRow}>
      <td className={styles.checkboxRow}>
        <Checkbox name={`user.${user.id}`} checked={checked} noMargin={true} onChange={() => checkboxChange(user.id)} />
      </td>
      <td className={styles.idRow}>{user.id}</td>
      <td className={styles.emailRow}>
        {user.email && <strong data-test-id='addUsersPage.userListTable.userListItem.email'>{user.email}</strong>}
        <p>{user.login}</p>
      </td>
      <td className={styles.nameRow}>
        <p data-test-id='addUsersPage.userListTable.userListItem.fullName'>{user.fullName}</p>
        <p data-test-id='addUsersPage.userListTable.userListItem.phone'>{user.phone}</p>
      </td>
      <td className={styles.accountsRow} data-test-id='addUsersPage.userListTable.userListItem.accounts'>
        <p>{accountString}</p>
      </td>
    </tr>
  );
};

export default React.memo(AddUserTableRow);
