import React, { createRef, SyntheticEvent } from 'react';
import { history } from 'App';
import classNames from 'classnames';
// @ts-ignore
import Cookies from 'universal-cookie';
import { Button, InputText } from '@just-ai/just-ui';

import localize, { t } from 'localization';
import { getDomainData, isAxiosError, isDev } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import ProjectGroupsService from 'service/ProjectGroupService';
import { BasePage, Error } from 'views/BasePage';

import { ProjectGroupCreateLocalization } from './localization/ProjectGroupCreate.loc';

const cookies = new Cookies();

localize.addTranslations(ProjectGroupCreateLocalization);

class ProjectGroupCreate extends BasePage<any, any> {
  static contextType = AppContext;
  ProjectGroupsService = new ProjectGroupsService();
  LoginService = new LoginService();
  groupName = createRef<HTMLInputElement>();
  recaptchaInstance: any;
  state = {
    fetching: false,
    errors: [],
    loaded: true,
    gRecaptchaResponse: null,
  };

  componentDidMount() {
    const load = async () => {
      this.setState({ fetching: true });
      const { setCurrentUser } = this.context;
      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        setCurrentUser(data);
      } catch {
        history.push('/c/login');
      }
      this.setState({ fetching: false });
    };

    load();
  }

  componentWillUnmount() {
    this.captchaReadyInterval && clearInterval(this.captchaReadyInterval);
  }

  validate = () => {
    const { errors } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: { args: { path: string } }[] = [];

    if (this.groupName.current?.value === '') {
      fieldErrors.push({
        args: {
          path: 'fullName',
        },
      });
    }

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { appConfig } = this.context;

    const {
      location: { search },
    } = this.props;

    const { redirectUrl } = getDomainData(search, appConfig?.domains);

    if (!this.validate()) return;

    this.setState({
      fetching: true,
    });

    if (appConfig?.captcha?.enabled) {
      await this.executeCaptcha();
    }

    try {
      const data = await this.ProjectGroupsService.createProjectGroup(
        this.groupName.current!.value,
        this.state.gRecaptchaResponse
      );
      cookies.set('SELECTED_ACCOUNT', String(data), { path: '/' });
      window.location.href = isDev() ? '/' : (redirectUrl as string);

      this.setState({
        success: true,
        fetching: false,
        errors: [],
      });
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];

        this.resetCaptcha();
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  renderHead = () => (
    <div className='base-page_formarea-head select-group-page'>
      <h1>{t('ProjectGroupCreate:Title')}</h1>
    </div>
  );

  renderInputs = () => {
    return (
      <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('fullName')) })}>
        <label htmlFor='fullName'>{t('ProjectGroupCreate:Name:Label')}</label>
        <InputText name='fullName' id='fullName' innerRef={this.groupName} />
        {this.renderFieldError('fullName')}
      </div>
    );
  };

  renderButtons = () => (
    <div className='base-page_formarea-buttons'>
      <Button
        color='secondary'
        outline
        onClick={e => {
          e.preventDefault();
          history.push('/c/select-project-group');
        }}
      >
        {t('ProjectGroupCreate:Button:Cancel')}
      </Button>
      <Button color='primary'>{t('ProjectGroupCreate:Button:Submit')}</Button>
    </div>
  );
}

export default ProjectGroupCreate;
