import React, { useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { FormGroup, InputText, Label, usePromiseProcessing } from '@just-ai/just-ui';
import { FormFeedback } from '@just-ai/just-ui/dist';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { Modal } from 'components/Modal';
import { BEError } from 'helpers/createBeErrors';

const accountsadminService = new AccountsadminService(axios);

type RenameAccountModalProps = {
  account: {
    id?: number;
    name?: string;
    projectReportsEnabled?: boolean;
  } | null;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};
const RenameAccountModal = ({ onSubmit, onCancel, isOpen, account }: RenameAccountModalProps) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!isOpen) return;
    setName(account?.name || '');
    setError('');
  }, [account?.name, isOpen]);

  const onSubmitInner = useCallback(async () => {
    if (!account || !account.id) return;
    const fullName = name.trim();
    if (!fullName) {
      return setError('required.field');
    }
    await accountsadminService.updateAccount(account.id, {
      fullName,
      enabled: !!account.projectReportsEnabled,
    });
    onSubmit();
  }, [account, name, onSubmit]);

  const [onSubmitInnerState, callOnSubmitInner] = usePromiseProcessing(onSubmitInner, {
    onError: e => {
      const error = (e as AxiosError)?.response?.data;
      if (error) setError((error as BEError)?.error || '');
    },
    deps: [onSubmitInner],
  });

  return (
    <Modal
      isOpen={isOpen}
      inProgress={onSubmitInnerState.loading}
      title={t('RenameAccountModal:Title')}
      buttonSubmitText={t('RenameAccountModal:Submit')}
      buttonCancelText={t('Cancel')}
      disableActionButtonAutoFocus
      onCancelClick={onCancel}
      onActionClick={callOnSubmitInner}
      data-test-id='RenameAccountModal'
      titleTestId='RenameAccountModal.header'
      buttonSubmitTestId='RenameAccountModal.submitButton'
      buttonCancelTestId='RenameAccountModal.rejectButton'
    >
      <FormGroup onSubmit={onSubmit} className='mb-0'>
        <Label>{t('RenameAccountModal:Form:Name:Label')}</Label>
        <InputText value={name} onChange={setName} data-test-id='RenameAccountModal.Input.Name' maxLength={200} />
        {error && (
          <FormFeedback
            data-test-id='RenameAccountModal:Error'
            valid={false}
            tag='div'
            style={{ display: 'block', position: 'relative' }}
          >
            {t(`RenameAccountModal:Error:${error}`)}
          </FormFeedback>
        )}
      </FormGroup>
    </Modal>
  );
};

export default React.memo(RenameAccountModal);
