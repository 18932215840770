import React, { Component } from 'react';

import { t } from 'localization';

class ResendTimer extends Component<
  {
    finishTimer: () => unknown;
    timer: number;
    timerText?: string;
    attemptsCount?: number;
    compact?: boolean;
    hidden?: boolean;
  },
  { timer: number }
> {
  constructor(props: { finishTimer: () => unknown; timer: number }) {
    super(props);

    this.state = {
      timer: props.timer,
    };
  }

  timerId = 0;

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate(prevProps: Readonly<{ finishTimer: () => unknown; timer: number }>) {
    if (prevProps.timer !== this.props.timer && this.timerId) {
      window.clearTimeout(this.timerId);
      this.startTimer();
    }
  }

  componentWillUnmount() {
    if (this.timerId) {
      window.clearTimeout(this.timerId);
    }
  }

  startTimer = () => {
    this.timerId = window.setTimeout(() => {
      if (this.state.timer > 0) {
        this.setState({
          timer: this.state.timer - 1,
        });
        this.startTimer();
      } else {
        this.props.finishTimer();
      }
    }, 1000);
  };

  render() {
    const { timerText, attemptsCount, compact, hidden } = this.props;
    if (hidden) {
      return null;
    }
    return compact ? (
      <small>{t(timerText ?? 'VerifyEmail: resend email text', this.state.timer, attemptsCount)}</small>
    ) : (
      <p>{t(timerText ?? 'VerifyEmail: resend email text', this.state.timer, attemptsCount)}</p>
    );
  }
}

export default ResendTimer;
