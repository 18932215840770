import { history } from 'App';
import queryString from 'query-string';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Button } from '@just-ai/just-ui';

import localization, { t } from 'localization';
import { axios, isAxiosError } from 'pipes/functions';

import { AppContext } from 'components/AppContext';
import LoginService from 'service/LoginService';
import { BasePage, BasePropTypes, Error } from 'views/BasePage';

import { extensionPageLocalization } from './localization/extensionPage.loc';

localization.addTranslations(extensionPageLocalization);
class State {
  loaded: boolean = true;
  errors: Error[] | [] = [];
  fetching: boolean = false;
}

class ExtensionPage extends BasePage<BasePropTypes, State> {
  state = new State();
  static contextType = AppContext;
  accountsadminService = new AccountsadminService(axios);
  LoginService = new LoginService();

  componentDidMount() {
    const load = async () => {
      this.setState({ fetching: true });
      const { setCurrentUser } = this.context;
      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();

        setCurrentUser(data);
      } catch (error) {
        history.push(`/c/login?redirectUrl=${encodeURIComponent(window.location.href)}`);
      }
      this.setState({ fetching: false });
    };

    load();
  }

  performAuth = async () => {
    try {
      const { location } = this.props;

      const parsedParams = queryString.parse(location.search);
      if (parsedParams.codeChallenge) {
        const authTokenData = await this.accountsadminService.createThirdPartyToken(
          parsedParams.codeChallenge as string
        );

        window.location.href = `vscode://justai.jaicp/did-authenticate?token=${encodeURIComponent(
          authTokenData.token
        )}`;
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.setState({
          errors: errors,
        });
      }
    }
  };

  cancel = async () => {
    window.location.href = '/';
  };

  renderHead = () => {
    const { currentUser } = this.context;
    return (
      <>
        <h3 className='mb-4'>
          {t('ExtensionPage:Title', {
            email: currentUser?.userData.login || currentUser?.userData.email,
            name: currentUser?.userData.fullName,
          })}
        </h3>
        <p>
          {t('ExtensionPage:Subtitle', {
            email: currentUser?.userData.login || currentUser?.userData.email,
            name: currentUser?.userData.fullName,
          })}
        </p>
      </>
    );
  };

  renderButtons = () => {
    return (
      <div className='base-page_formarea-buttons flex-row gap-3x'>
        <Button color='primary' onClick={this.performAuth}>
          {t('ExtensionPage:Agree')}
        </Button>
        <Button color='primary' onClick={this.cancel}>
          {t('ExtensionPage:Cancel')}
        </Button>
      </div>
    );
  };
}

export default ExtensionPage;
