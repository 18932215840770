import { useCallback, useState, useEffect } from 'react';
import { clamp } from 'lodash';

type PaginationInfo = { pageNum: number; pageSize: number; totalCount: number };

function normalizePaginationInfo(info: PaginationInfo): PaginationInfo {
  const pagesCount = info.pageSize === 0 ? 0 : Math.ceil(info.totalCount / info.pageSize);
  info.pageNum = clamp(info.pageNum, 0, pagesCount - 1);
  return info;
}

export function usePagination(paging: PaginationInfo) {
  const [paginationInfo, setPaginationInfo] = useState(paging);

  useEffect(() => {
    setPaginationInfo({
      pageNum: paging.pageNum,
      pageSize: paging.pageSize,
      totalCount: paging.totalCount,
    });
  }, [paging.pageNum, paging.pageSize, paging.totalCount]);

  const changePage = useCallback(
    (pageNum: number) =>
      setPaginationInfo(prev =>
        normalizePaginationInfo({
          ...prev,
          pageNum,
        })
      ),
    []
  );
  const changePageSize = useCallback(
    (pageSize: number) =>
      setPaginationInfo(prev =>
        normalizePaginationInfo({
          ...prev,
          pageSize,
        })
      ),
    []
  );
  const changeTotalPages = useCallback(
    (totalCount: number) =>
      setPaginationInfo(prev =>
        normalizePaginationInfo({
          ...prev,
          totalCount,
        })
      ),
    []
  );

  return { paginationInfo, changePage, changePageSize, changeTotalPages };
}
