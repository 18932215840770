import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountInvitationSearchDto } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Label, usePromiseProcessing } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';
import { appStores } from 'store';

import GroupedSelector, { OptionsGroup } from 'components/GroupedSelector';
import { Modal } from 'components/Modal';
import { toOption } from 'helpers/toOption';

import '../styles.scss';

const accountsadminService = new AccountsadminService(axios);

type EditInviteModalProps = {
  accountInvitation: AccountInvitationSearchDto | undefined;
  accountId: number | undefined;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};
const EditInviteModal = ({ onSubmit, onCancel, isOpen, accountInvitation, accountId }: EditInviteModalProps) => {
  const invitationRoles = useMemo(
    () => accountInvitation?.userRoles.map(role => role.name) || [],
    [accountInvitation?.userRoles]
  );

  const [roles, setRoles] = useState<string[]>(invitationRoles);
  const { groupsAndRoles } = appStores.Accounts(state => ({ groupsAndRoles: state.groupsAndRoles }));

  useEffect(() => {
    if (!isOpen) return;
    setRoles(invitationRoles);
  }, [invitationRoles, isOpen]);

  useEffect(() => {
    if (accountInvitation?.userRoles) {
      setRoles(invitationRoles);
    }
  }, [invitationRoles, accountInvitation?.userRoles]);

  const onSubmitInner = useCallback(async () => {
    if (!accountInvitation || !accountInvitation.invitationId || !accountId) return;
    await accountsadminService.changeInvitationRoles(accountId, accountInvitation.invitationId, roles);
    onSubmit();
  }, [accountId, accountInvitation, onSubmit, roles]);

  const [onSubmitInnerState, callOnSubmitInner] = usePromiseProcessing(onSubmitInner, {
    deps: [onSubmitInner],
  });

  const groupsToOptions: OptionsGroup[] = groupsAndRoles.map(group => ({
    label: group.name,
    value: group.name,
    list: group.roles.map(role => toOption(role.name)),
  }));

  return (
    <Modal
      isOpen={isOpen}
      title={t('EditInviteModal:Title')}
      onActionClick={callOnSubmitInner}
      onCancelClick={onCancel}
      inProgress={onSubmitInnerState.loading}
      disableActionButtonAutoFocus
      data-test-id='EditInviteModal'
      titleTestId='EditInviteModal.header'
      buttonSubmitTestId='EditInviteModal.submitButton'
      buttonCancelTestId='EditInviteModal.rejectButton'
      buttonSubmitDisabled={!roles.length}
      buttonCancelText={t('Cancel')}
      buttonSubmitText={t('EditInviteModal.submit')}
      id='EditInviteModal'
    >
      <div className='flex-column gap-6x'>
        <div>{accountInvitation?.email}</div>
        <div className='flex-column'>
          <Label>{t('EditInviteModal:Field:Roles')}</Label>
          <GroupedSelector groups={groupsToOptions} selected={roles} onChange={setRoles} />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(EditInviteModal);
