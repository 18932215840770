export const resetpasswordLocalization = {
  'ResetPassword: form header text': {
    eng: 'Create new password',
    ru: 'Создание нового пароля',
    pt: 'Criar nova senha',
    cn: 'Create new password',
  },
  'ResetPassword: form header success text': {
    eng: 'You successfully changed the password',
    ru: 'Пароль успешно изменен',
    pt: 'Você alterou a senha com sucesso',
    cn: 'You successfully changed the password',
  },
  'ResetPassword: form header error text accountsadmin.autologin.expired.token': {
    eng: 'The link has expired',
    ru: 'Срок действия ссылки истек',
    pt: 'O link expirou',
    cn: 'The link has expired',
  },
  'ResetPassword: form header error text accountsadmin.autologin.invalid.token': {
    eng: 'Incorrect token',
    ru: 'Неверный токен',
    pt: 'Token incorreto',
    cn: 'Incorrect token',
  },
  'ResetPassword: field repeat password label': {
    eng: 'Reenter password',
    ru: 'Повторите пароль',
    pt: 'Digite novamente a senha',
    cn: 'Reenter password',
  },
  'ResetPassword: submit button text': {
    eng: 'Save password',
    ru: 'Сохранить',
    pt: 'Salvar senha',
    cn: 'Save password',
  },
  'ResetPassword: submit button error text': {
    eng: 'Resend the link',
    ru: 'Отправить ссылку еще раз',
    pt: 'Reenviar o link',
    cn: 'Resend the link',
  },
  'ResetPassword: submit button success text': {
    eng: 'Continue',
    ru: 'Продолжить',
    pt: 'Continuar',
    cn: 'Continue',
  },
  'ResetPassword: field password label': {
    eng: 'New password',
    ru: 'Новый пароль',
    pt: 'Nova senha',
    cn: 'New password',
  },
  'ResetPassword: form header error text accountsadmin.authorization.account.blocked': {
    eng: 'This account is blocked. Please contact our customer support team.',
    ru: 'Данный аккаунт заблокирован. Обратитесь в техническую поддержку.',
    pt: 'Esta conta está bloqueada. Entre em contato com nossa equipe de suporte ao cliente.',
    cn: 'This account is blocked. Please contact our customer support team.',
  },
  'BasePage:BE-error accountsadmin.authorization.user.disabled': {
    eng: 'This user is blocked. Please contact our customer support team.',
    ru: 'Данный пользователь заблокирован. Обратитесь в техническую поддержку.',
    pt: 'Esta usuário está bloqueada. Entre em contato com nossa equipe de suporte ao cliente.',
    cn: 'This user is blocked. Please contact our customer support team.',
  },
};
