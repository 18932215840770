import { useAccountsStore } from './slices/accounts';
import { useConfigStore } from './slices/configs';
import { useCurrentUser } from './slices/currentUser';
import { useFeaturesStore } from './slices/features';
import { useUsersStore } from './slices/users';

export const appStores = {
  Features: useFeaturesStore,
  Users: useUsersStore,
  Accounts: useAccountsStore,
  Config: useConfigStore,
  CurrentUser: useCurrentUser,
};

Object.freeze(appStores);
